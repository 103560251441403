import React, { useState, useEffect, useContext, useRef } from "react";
import { useAuth, getAuthData } from "../../context/AuthContext";
import { analytics } from "../../firebase";
import "../../style/dashboard.css";
import DownloadIconBlack from "../../images/dashboard/download.svg";
import DownloadDisabled from "../../images/dashboard/download_disabled.svg";
import { Container } from "@mui/system";
import Layout from "../Layout";
import Box from "@mui/material/Box";
import "../../style/gallery.css";
import FreeUser from "./free";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logEvent } from "@firebase/analytics";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "../dashboard/Dashboard.css";
import Upload from "../gallery/upload";
import pause from "../../images/gallery/Pause.svg";
import { UploadContext } from "../App";
import { ArrowBack } from "@mui/icons-material";
import ModalEditSession from "../common/ModalEditSession";
import ModalDeleteSession from "../common/ModalDeleteSession";
import Multiselect from "../gallery/multiselect";
import firestoreRepository, {
  listenDeviceSyncChanges,
} from "../../repositories/firestoreRepository";
import Gallery from "../gallery/fileDetailedView";
import { downloadFileWithProgress } from "../gallery/downloadFile";
import info from "../subscription/info.svg";
import { SessionOwner } from "../../utils/ownerUtils";
import { SessionTechnician } from "../../utils/technicianUtils";
import PatientModal from "../common/ModalPatientInfo";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  let { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [authData, setAuthData] = useState({});
  const entityId = useRef(null);
  const isOrgUser = useRef(null);
  const {
    setFiles,
    setDownloadFiles,
    setUploadProgress,
    setDownloadProgress,
    setDownloadThumbnails,
    setUploadThumbnails,
    setRemainingTimes,
    renderVideo,
    setRenderVideo,
    setRemainingDownloadTime,
  } = useContext(UploadContext);
  const [downloadingFiles, setDownloadingFiles] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [multiselectedFiles, setMultiselectedFiles] = useState([]);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [multiselectedUrls, setMultiselectedUrls] = useState([]);
  const [openedFile, setOpenedFile] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [session, setSession] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [patient, setPatient] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [userData, setUserData] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("All");
  const lastDeviceSyncRef = useRef();
  const dltPermission = useRef();
  const rdPermissions = useRef();
  const shrPermission = useRef();
  const wrtPermissions = useRef(0);

  const setLastDeviceSync = (data) => {
    lastDeviceSyncRef.current = data;
  };
  const debounceTimer = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (entityId.current === null || isOrgUser.current === null) {
      return;
    }
    loadData(entityId.current, isOrgUser.current, id);
  }, [needReload]);

  useEffect(() => {
    if (isOrgUser.current === true) {
      loadOrgData(entityId.current).then((data) => {
        setLastDeviceSync(data.lastDeviceSync);
        setLargestSubscriptionId(data.stripeSubscriptionId || 0);
      });
      firestoreRepository.getUserClaims(currentUser.uid).then((claims) => {
        firestoreRepository.getOrgData(claims.orgId).then((orgData) => {
          const currentUserData = orgData.users[currentUser.uid];
          if (currentUserData) {
            dltPermission.current = currentUserData.pDlt;
            rdPermissions.current = currentUserData.pRd;
            shrPermission.current = currentUserData.pShr;
            wrtPermissions.current = currentUserData.pWrt;
          } else {
            console.error("Current user data not found in organization data.");
          }
        });
      });
    } else {
      loadUserData(currentUser.uid).then((data) => {
        setUserData(data);
        setLastDeviceSync(data.lastDeviceSync);
        setLargestSubscriptionId(
          Math.max(
            data.stripeSubscriptionId || 0,
            data.iosSubscriptionId || 0,
            data.androidSubscriptionId || 0,
            data.premiumAndroid ? 2 : 0,
            data.premiumIOS ? 2 : 0
          )
        );
      });
    }
  }, [isOrgUser.current]);

  useEffect(() => {
    getAuthData()
      .then((authData) => {
        setAuthData(authData);
        // Set entityId and isOrgUser based on the user's claims
        if (!authData.claims.orgId) {
          entityId.current = currentUser.uid;
          isOrgUser.current = false;
        } else {
          entityId.current = authData.claims.orgId;
          isOrgUser.current = true;
        }

        // Set up listener for device sync changes

        const fetchData = async () => {
          const loadDataCallback = () => {
            loadData(entityId.current, isOrgUser.current, id);
          };
          const unsubscribe = await listenDeviceSyncChanges(
            entityId.current,
            isOrgUser.current,
            lastDeviceSyncRef,
            setLastDeviceSync,
            loadDataCallback,
            debounceTimer
          );

          return () => unsubscribe();
        };

        fetchData();
        setNeedReload(!needReload);
      })
      .catch((error) => {
        console.error("Error getting auth data: ", error);
      });
  }, [currentUser]);

  const handleSelectImage = (media) => {
    if (multiselectedFiles.find((item) => item.globalId === media.globalId)) {
      setMultiselectedFiles(
        multiselectedFiles.filter((item) => item.globalId !== media.globalId)
      );
    } else {
      setMultiselectedFiles([...multiselectedFiles, media]);
    }
  };

  function handleImageClick(url) {
    if (multiselectedUrls.includes(url)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== url)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, url]);
    }
  }

  const handleDownload = (file) => {
    downloadFileWithProgress(
      file,
      setDownloadFiles,
      setDownloadProgress,
      setDownloadThumbnails,
      setRemainingDownloadTime,
      downloadingFiles,
      setDownloadingFiles
    );
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setMultiselectedFiles([]);
    setMultiselectedUrls([]);
  };

  const loadData = async (entityId, isOrgUser, globalId) => {
    loadSessions(entityId, isOrgUser, globalId).then((session) => {
      setSession(session);

      loadImages(entityId, isOrgUser, session).then((images) => {
        setImages(images);
      });
      loadVideos(entityId, isOrgUser, session).then((videos) => {
        setVideos(videos);
      });
      loadPatients(entityId, isOrgUser, session).then((patient) => {
        setPatient(patient);
      });
      setTimeout(() => setIsLoading(false), 100);
    });
    // Since we need all tags, they are independent from the session query
    loadAllTags(entityId, isOrgUser).then((tags) => {
      setAllTags(tags);
    });
    loadAllPatients(entityId, isOrgUser).then((patients) => {
      setAllPatients(patients);
    });
  };

  /**
   * Loads session from the Firestore database.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Array} - An array of session data.
   */
  const loadSessions = async (entityId, isOrgUser, globalId) => {
    const sessionData = await firestoreRepository.getSessionById(
      entityId,
      isOrgUser,
      globalId
    );
    return sessionData;
  };

  /**
   * Loads images for a given user and session data.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Object} - An object mapping session global IDs to session images.
   */
  const loadImages = async (entityId, isOrgUser, globalId) => {
    const sessionImages = await firestoreRepository.getImagesBySession(
      entityId,
      isOrgUser,
      globalId
    );
    return sessionImages;
  };

  /**
   * Loads videos for a given user and session data.
   *
   * @param {string} entityId - The ID of either the user or the organization.
   * @param {boolean} isOrgUser - A boolean indicating whether the user belongs to an organization.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Object} - An object mapping session global IDs to session videos.
   */
  const loadVideos = async (entityId, isOrgUser, globalId) => {
    const sessionVideos = await firestoreRepository.getVideosBySession(
      entityId,
      isOrgUser,
      globalId
    );

    return sessionVideos;
  };

  const loadPatients = async (entityId, isOrgUser, globalId) => {
    const sessionPatient = await firestoreRepository.getPatientBySession(
      entityId,
      isOrgUser,
      globalId
    );
    return sessionPatient;
  };

  const loadAllPatients = async (entityId, isOrgUser) => {
    const patientsData = await firestoreRepository.getAllPatients(
      entityId,
      isOrgUser
    );
    return patientsData;
  };

  const loadAllTags = async (entityId, isOrgUser) => {
    const tags = await firestoreRepository.getAllTags(entityId, isOrgUser);
    return tags;
  };

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const loadOrgData = async (orgId) => {
    const data = await firestoreRepository.getOrgData(orgId);
    return data;
  };

  const openGallery = (media, mediaArray) => {
    logEvent(analytics, "file_open", {
      button_name: "file_open",
    });
    if (multiselectedUrls.includes(media.downloadURL)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== media.downloadURL)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, media.downloadURL]);
    }
    setAllFiles(mediaArray);
    setOpenedFile([media]);
    setMultiselectedFiles([]);

    if (media.globalId.includes("VIDEO")) {
      setGalleryVisible(true);
      navigate("?video");
    } else if (media.globalId.includes("IMAGE")) {
      setGalleryVisible(true);
      navigate("?image");
    } else {
      setGalleryVisible(false);
    }
  };

  const allowedToWrite =
    (isOrgUser.current &&
      currentUser.uid === session.owner &&
      wrtPermissions.current === 1) ||
    (isOrgUser.current && wrtPermissions.current === 2) ||
    !isOrgUser.current;

  const noShrPermission =
    shrPermission.current === 0 ||
    (currentUser.uid !== session.owner && shrPermission.current === 1);

  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
        </div>
      ) : (
        <>
          {largestSubscriptionId > 0 ? (
            <>
              {session.deleted === true ? (
                <Container maxWidth="lg" className="container_top">
                  <div>
                    <a href="/" className="back_link">
                      <IconButton size="small">
                        <ArrowBack />
                      </IconButton>
                    </a>
                    <div className="deleted_session">
                      This session was deleted
                    </div>
                  </div>
                </Container>
              ) : (
                <Container maxWidth="lg" className="container_bottom">
                  {multiselectedFiles.length != 0 && (
                    <Multiselect
                      entityId={entityId.current}
                      isOrgUser={isOrgUser.current}
                      setDownloadFiles={setDownloadFiles}
                      setDownloadProgress={setDownloadProgress}
                      setDownloadThumbnails={setDownloadThumbnails}
                      setRemainingDownloadTime={setRemainingDownloadTime}
                      multiselectedUrls={multiselectedUrls}
                      currentUser={currentUser.uid}
                      reloadCallback={() => setNeedReload(!needReload)}
                      multiselectedFiles={multiselectedFiles}
                      setMultiselectedFiles={setMultiselectedFiles}
                      setMultiselectedUrls={setMultiselectedUrls}
                      sessionId={id}
                      downloadingFiles={downloadingFiles}
                      setDownloadingFiles={setDownloadingFiles}
                      dltPermission={dltPermission.current}
                      shrPermission={shrPermission.current}
                      sessionOwner={session.owner}
                    />
                  )}
                  <div className="flex_container">
                    <Link to="/" className="back_link">
                      <IconButton size="small">
                        <ArrowBack />
                      </IconButton>
                    </Link>

                    <div>
                      <div className="flex_div_center">
                        {(() => {
                          const mediaArray = [
                            ...(Array.isArray(videos) ? videos : []),
                            ...(Array.isArray(images) ? images : []),
                          ].sort(
                            (a, b) =>
                              new Date(b.createdAt.toDate()) -
                              new Date(a.createdAt.toDate())
                          );

                          return (
                            <Tooltip
                              title={
                                noShrPermission
                                  ? "You don't have the required permissions to download files"
                                  : ""
                              }
                            >
                              <button
                                className="button_no_style edit_session_button"
                                value={session}
                                disabled={noShrPermission}
                                onClick={() => {
                                  mediaArray.forEach((media) => {
                                    if (!downloadingFiles.has(media.globalId)) {
                                      handleDownload({
                                        name: media.globalId,
                                        url: media.downloadURL,
                                        size: media.size,
                                        thumbnail: media.thumbnailURL,
                                      });
                                    }
                                  });
                                }}
                              >
                                <img
                                  src={
                                    noShrPermission
                                      ? DownloadDisabled
                                      : DownloadIconBlack
                                  }
                                  className="edit_session_icon"
                                />
                              </button>
                            </Tooltip>
                          );
                        })()}

                        {session && entityId.current && (
                          <>
                            <ModalEditSession
                              key={`mes-${session.globalId}-${patient?.patientId?.length}-${allTags?.length}`}
                              sessionObj={session}
                              allPatients={allPatients}
                              patientName={
                                patient ? patient.patientId : undefined
                              }
                              allTagsArray={allTags}
                              entityId={entityId.current}
                              isOrgUser={isOrgUser.current}
                              reloadCallback={() => setNeedReload(!needReload)}
                              owner={session.owner}
                              currentUser={currentUser.uid}
                              wrtPermissions={wrtPermissions.current}
                            />
                            <ModalDeleteSession
                              key={`mds-${session.globalId}`}
                              sessionId={session.globalId}
                              entityId={entityId.current}
                              isOrgUser={isOrgUser.current}
                              reloadCallback={() => setNeedReload(!needReload)}
                              dltPermission={dltPermission.current}
                              sessionOwner={session.owner}
                              currentUser={currentUser.uid}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <>
                    <div className="data_flex_session data_flex_session_inside">
                      <Grid
                        item
                        xs={5.8}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label className="label">PATIENT ID:</label>
                        <p className="input_style edit_modal_input">
                          {patient.patientId && patient.patientId.length > 0 ? (
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "0px",
                              }}
                            >
                              {patient.patientId}
                              {/* <div></div> */}
                              {/* <PatientModal
                                entityId={entityId.current}
                                isOrgUser={isOrgUser.current}
                                wrtPermissions={wrtPermissions.current}
                                firstName={patient.firstName}
                                lastName={patient.lastName}
                                patientId={patient.patientId}
                                birthDate={patient.birthDate}
                                gender={patient.genderIdx}
                                allPatients={allPatients}
                                reloadCallback={() =>
                                  setNeedReload(!needReload)
                                }
                              /> */}
                            </p>
                          ) : (
                            <span className="no_patient_id">
                              No patient ID assigned
                            </span>
                          )}
                        </p>
                        <label className="label margin_top_1">DATE:</label>
                        <p className="input_style edit_modal_input">
                          {session.createdAt?.toDate().toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: false,
                          })}
                        </p>
                        <label className="label margin_top_1">TAGS:</label>
                        <div className="width_100" style={{ width: "100%" }}>
                          {session.tags && session.tags.length > 0 ? (
                            <div
                              className="tags_block width_100"
                              style={{ width: "100%" }}
                            >
                              {session.tags?.map((tag) => (
                                <div
                                  key={`tagsBlock-${tag}`}
                                  className=" margin_top_wrap tags_div"
                                  style={{ width: "auto" }}
                                >
                                  {`${tag}`
                                    ? `${tag}`.length > 25
                                      ? `${tag}`.slice(0, 25) + "..."
                                      : `${tag}`
                                    : ""}
                                </div>
                              ))}{" "}
                            </div>
                          ) : (
                            <span className="no_tags_added_div">
                              No tags added
                            </span>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={5.8}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {session && isOrgUser.current && (
                          <>
                            <label className="label margin_bottom_05">
                              CLINICIAN:
                            </label>
                            <SessionOwner uid={session.owner} />
                            <label className="label margin_bottom_05 margin_top_1">
                              TECHNICIAN:
                            </label>
                            <SessionTechnician uid={session.technician} />
                          </>
                        )}

                        <label className="label margin_bottom_05 margin_top_1">
                          Comment:
                        </label>
                        <Grid
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "100%",
                          }}
                          item
                          className="description-wrap"
                        >
                          <Typography>
                            {session.description?.length > 0
                              ? session.description
                              : "A comment or note about a patient, photo or video description"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </>

                  {session && entityId.current && (
                    <Upload
                      setUploadProgress={setUploadProgress}
                      setUploadThumbnails={setUploadThumbnails}
                      setFiles={setFiles}
                      setRemainingTimes={setRemainingTimes}
                      entityId={entityId.current}
                      isOrgUser={isOrgUser.current}
                      reloadCallback={() => setNeedReload(!needReload)}
                      renderVideo={renderVideo}
                      setRenderVideo={setRenderVideo}
                      sessionId={id}
                      largestSubscriptionId={largestSubscriptionId}
                      userData={userData}
                      isAllowedToWrite={allowedToWrite}
                    />
                  )}
                  <div className="margin_left_1">
                    <FormControl>
                      <RadioGroup
                        onChange={handleChange}
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="All"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="All"
                          control={
                            <Radio
                              {...a11yProps(0)}
                              sx={{
                                color: "#8F4FFF",
                                "&.Mui-checked": {
                                  color: "#8F4FFF",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                        <FormControlLabel
                          value="Videos"
                          control={
                            <Radio
                              {...a11yProps(1)}
                              sx={{
                                color: "#8F4FFF",
                                "&.Mui-checked": {
                                  color: "#8F4FFF",
                                },
                              }}
                            />
                          }
                          label="Videos"
                        />
                        <FormControlLabel
                          value="Images"
                          control={
                            <Radio
                              {...a11yProps(2)}
                              sx={{
                                color: "#8F4FFF",
                                "&.Mui-checked": {
                                  color: "#8F4FFF",
                                },
                              }}
                            />
                          }
                          label="Images"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {images && videos ? (
                    <TabPanel
                      value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                      index={0}
                    >
                      <div className="images_page">
                        {(() => {
                          const mediaArray = [
                            ...(largestSubscriptionId === 1
                              ? []
                              : Array.isArray(videos)
                              ? videos
                              : []),
                            ...(Array.isArray(images) ? images : []),
                          ].sort(
                            (a, b) =>
                              new Date(b.createdAt.toDate()) -
                              new Date(a.createdAt.toDate())
                          );
                          return [...Array(mediaArray.length)].map(
                            (_, index) => {
                              const media = mediaArray[index];
                              return (
                                <React.Fragment key={`${id}_${index}`}>
                                  <div>
                                    <div className="hover_over">
                                      <link
                                        rel="preload"
                                        as="image"
                                        href={media.thumbnailSessionURL}
                                      ></link>
                                      <div className="image-container">
                                        {media.globalId.includes("VIDEO") && (
                                          <div className="pause_button">
                                            <img src={pause}></img>
                                          </div>
                                        )}
                                        <img
                                          src={media.thumbnailSessionURL}
                                          alt=""
                                          className="myimg"
                                          onError={async (e) => {
                                            try {
                                              const refetchedMedia =
                                                await firestoreRepository.refetchDownloadUrls(
                                                  entityId.current,
                                                  isOrgUser.current,
                                                  media
                                                );
                                              if (refetchedMedia) {
                                                const isImage =
                                                  refetchedMedia.globalId.includes(
                                                    "IMAGE"
                                                  );
                                                e.target.src =
                                                  refetchedMedia.thumbnailSessionURL;
                                                if (isImage) {
                                                  setImages((prevImages) => {
                                                    const currentImages =
                                                      prevImages[
                                                        session.globalId
                                                      ] || [];
                                                    return {
                                                      ...prevImages,
                                                      [session.globalId]: [
                                                        ...currentImages.filter(
                                                          (image) =>
                                                            image.globalId !==
                                                            media.globalId
                                                        ),
                                                        refetchedMedia,
                                                      ],
                                                    };
                                                  });
                                                } else {
                                                  setVideos((prevVideos) => {
                                                    const currentVideos =
                                                      prevVideos[
                                                        session.globalId
                                                      ] || [];
                                                    return {
                                                      ...prevVideos,
                                                      [session.globalId]: [
                                                        ...currentVideos.filter(
                                                          (video) =>
                                                            video.globalId !==
                                                            media.globalId
                                                        ),
                                                        refetchedMedia,
                                                      ],
                                                    };
                                                  });
                                                }
                                              }
                                            } catch (error) {
                                              console.error(
                                                "Error refreshing URL:",
                                                error
                                              );
                                            }
                                          }}
                                        />
                                        <div
                                          className="overlay"
                                          onClick={() =>
                                            openGallery(media, mediaArray)
                                          }
                                        ></div>
                                        <input
                                          type="checkbox"
                                          id="myCheckbox"
                                          checked={
                                            !!multiselectedFiles.find(
                                              (item) =>
                                                item.globalId === media.globalId
                                            )
                                          }
                                          onChange={() => {
                                            handleSelectImage(media);
                                            handleImageClick(media.downloadURL);
                                          }}
                                        />
                                        <label htmlFor="myCheckbox"></label>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          );
                        })()}
                      </div>
                    </TabPanel>
                  ) : (
                    ""
                  )}
                  {videos && largestSubscriptionId === 1 ? (
                    <TabPanel
                      value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                      index={1}
                    >
                      <div className="mini-panel-container">
                        <img src={info} className="mini-panel-img"></img>
                        <div>
                          <div className="mini-panel-header">
                            Video uploads are available for Standard and Premium
                            Tiers.
                          </div>
                          <div>
                            To access your videos in MicroREC Connect, check out
                            the{" "}
                            <a
                              href="/subscription/#subs"
                              className="mini-panel-link"
                            >
                              upgrade options.
                            </a>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ) : (
                    <TabPanel
                      value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                      index={1}
                    >
                      <div className="images_page">
                        {(() => {
                          const mediaArray = [...(videos || [])].sort(
                            (a, b) =>
                              new Date(b.createdAt.toDate()) -
                              new Date(a.createdAt.toDate())
                          );
                          return [...Array(mediaArray.length)].map(
                            (_, index) => {
                              const media = mediaArray[index];
                              return (
                                <React.Fragment key={`${id}_${index}`}>
                                  <div>
                                    <>
                                      <div className="hover_over">
                                        <>
                                          <link
                                            rel="preload"
                                            as="image"
                                            href={media.thumbnailSessionURL}
                                          ></link>
                                          <div className="image-container">
                                            <div className="pause_button">
                                              <img src={pause}></img>
                                            </div>
                                            <img
                                              src={media.thumbnailSessionURL}
                                              alt=""
                                              className="myimg"
                                              onError={async (e) => {
                                                try {
                                                  const refetchedMedia =
                                                    await firestoreRepository.refetchDownloadUrls(
                                                      entityId.current,
                                                      isOrgUser.current,
                                                      media
                                                    );

                                                  setVideos((prevVideos) => ({
                                                    ...prevVideos,
                                                    [session.globalId]: [
                                                      ...(prevVideos[
                                                        session.globalId
                                                      ]
                                                        ? prevVideos[
                                                            session.globalId
                                                          ].filter(
                                                            (video) =>
                                                              video.globalId !==
                                                              media.globalId
                                                          )
                                                        : []),
                                                      refetchedMedia,
                                                    ],
                                                  }));
                                                } catch (error) {
                                                  console.error(
                                                    "Error refreshing URL:",
                                                    error
                                                  );
                                                }
                                              }}
                                            ></img>
                                            <div
                                              className="overlay"
                                              onClick={() =>
                                                openGallery(media, mediaArray)
                                              }
                                            ></div>
                                            <input
                                              type="checkbox"
                                              id="myCheckbox"
                                              checked={
                                                !!multiselectedFiles.find(
                                                  (item) =>
                                                    item.globalId ===
                                                    media.globalId
                                                )
                                              }
                                              onChange={() => {
                                                handleSelectImage(media);
                                                handleImageClick(
                                                  media.downloadURL
                                                );
                                              }}
                                            />
                                            <label for="myCheckbox"></label>
                                          </div>
                                        </>
                                      </div>
                                    </>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          );
                        })()}
                      </div>
                    </TabPanel>
                  )}
                  {images ? (
                    <TabPanel
                      value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                      index={2}
                    >
                      <div className="images_page">
                        {(() => {
                          const mediaArray = [...(images || [])].sort(
                            (a, b) =>
                              new Date(b.createdAt.toDate()) -
                              new Date(a.createdAt.toDate())
                          );
                          return [...Array(mediaArray.length)].map(
                            (_, index) => {
                              const media = mediaArray[index];
                              return (
                                <React.Fragment key={`${id}_${index}`}>
                                  <div>
                                    <>
                                      <div className="hover_over">
                                        <>
                                          <link
                                            rel="preload"
                                            as="image"
                                            href={media.thumbnailSessionURL}
                                          ></link>
                                          <div className="image-container">
                                            <img
                                              src={media.thumbnailSessionURL}
                                              alt=""
                                              className="myimg"
                                              onError={async (e) => {
                                                try {
                                                  const refetchedMedia =
                                                    await firestoreRepository.refetchDownloadUrls(
                                                      entityId.current,
                                                      isOrgUser.current,
                                                      media
                                                    );
                                                  setImages((prevImages) => ({
                                                    ...prevImages,
                                                    [session.globalId]: [
                                                      ...(prevImages[
                                                        session.globalId
                                                      ]
                                                        ? prevImages[
                                                            session.globalId
                                                          ].filter(
                                                            (image) =>
                                                              image.globalId !==
                                                              media.globalId
                                                          )
                                                        : []),
                                                      refetchedMedia,
                                                    ],
                                                  }));
                                                } catch (error) {
                                                  console.error(
                                                    "Error refreshing URL:",
                                                    error
                                                  );
                                                }
                                              }}
                                            ></img>
                                            <div
                                              className="overlay"
                                              onClick={() =>
                                                openGallery(media, mediaArray)
                                              }
                                            ></div>
                                            <input
                                              type="checkbox"
                                              id="myCheckbox"
                                              checked={
                                                !!multiselectedFiles.find(
                                                  (item) =>
                                                    item.globalId ===
                                                    media.globalId
                                                )
                                              }
                                              onChange={() => {
                                                handleSelectImage(media);
                                                handleImageClick(
                                                  media.downloadURL
                                                );
                                              }}
                                            />
                                            <label htmlFor="myCheckbox"></label>
                                          </div>
                                        </>
                                      </div>
                                    </>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          );
                        })()}
                      </div>
                    </TabPanel>
                  ) : (
                    ""
                  )}
                  {galleryVisible ? (
                    <Gallery
                      sessionId={id}
                      galleryVisible={galleryVisible}
                      entityId={entityId.current}
                      isOrgUser={isOrgUser.current}
                      reloadCallback={() => setNeedReload(!needReload)}
                      openedFile={openedFile}
                      setOpenedFile={setOpenedFile}
                      setGalleryVisible={setGalleryVisible}
                      multiselectedUrls={multiselectedUrls}
                      multiselectedFiles={multiselectedFiles}
                      setMultiselectedFiles={setMultiselectedFiles}
                      setMultiselectedUrls={setMultiselectedUrls}
                      allFiles={allFiles}
                      downloadingFiles={downloadingFiles}
                      setDownloadingFiles={setDownloadingFiles}
                      dltPermission={dltPermission.current}
                      shrPermission={shrPermission.current}
                      sessionOwner={session.owner}
                      currentUser={currentUser.uid}
                    />
                  ) : (
                    ""
                  )}
                </Container>
              )}
            </>
          ) : (
            <FreeUser />
          )}
        </>
      )}
    </Layout>
  );
}

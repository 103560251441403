import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import {
  Box,
  Grid,
  IconButton,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  TextField,
  MenuItem,
  Autocomplete,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository, {
  updateLastDeviceSync,
} from "../../repositories/firestoreRepository";
import importPatient from "../dashboard/importActive.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, parse, set } from "date-fns";
import { enUS } from "date-fns/locale";
import { collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { ClientJS } from "clientjs";
import { db } from "../../firebase";
import addImage from "../../images/upload/csv_import.svg";
import addImageActive from "../../images/upload/csv_active.svg";
import addImageRejected from "../../images/upload/csv_rejected.svg";
import { EastOutlined } from "@mui/icons-material";
import "../../style/patient.css";
import ind_import from "../../images/dashboard/ind_import.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
    backgroundColor: "#FDFDFD",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: "20px",
  borderColor: "#8A8787",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
  padding: "20px",
};

const focusedStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const acceptStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const rejectStyle = {
  borderColor: "#ff1744",
  background: "rgba(255, 23, 68, 0.03)",
};

const CSVUpload = ({
  entityId,
  isOrgUser,
  ownerIds,
  patientIds,
  tags,
  wrtPermissions,
}) => {
  const [csvData, setCsvData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openBusiness, setOpenBusiness] = useState(false);
  const [patientIdDuplicate, setPatientIdDuplicate] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFields, setSelectedFields] = useState({});
  const [selectedFieldData, setSelectedFieldData] = useState([]);
  const [validationError, setValidationError] = useState("");
  const handleClose = () => {
    if (csvData.length > 0) {
      setOpenSave(true);
    } else {
      setOpen(false);
    }
  };
  const handleCloseSave = () => setOpenSave(false);

  const steps = [
    "Import Data",
    "CSV fields mapping",
    "Review Data & Create Sessions",
  ];
  const columnHeaders = [
    "Date",
    "Time",
    "Patient ID",
    "Clinician",
    "Description",
    "Tags",
  ];

  const isAllowedToWrite = wrtPermissions === 2 ? true : false;

  useEffect(() => {
    const fetchData = async () => {
      const patientsData = await firestoreRepository.getAllPatients(
        entityId,
        isOrgUser
      );
      var tempPatients = {};
      patientsData.forEach((patient) => {
        const globalId = patient.globalId;
        const canonicalId = patient.canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      setPatientIdDuplicate(tempPatients);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setValidationError("");
  }, [selectedRows, activeStep]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
        setSelectedRows(results.data.map((_, index) => index));
      },
    });
  }, []);

  const handleModalClose = () => {
    setOpen(false);
    setOpenSave(false);
    setCsvData([]);
    setActiveStep(0);
    setSelectedRows([]);
    setSelectedFields({});
    setSelectedFieldData([]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { "text/csv": [] },
      maxFiles: 1,
    });

  const getStyle = (isFocused, isDragAccept, isDragReject) => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  });

  const style = getStyle(isFocused, isDragAccept, isDragReject);

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((i) => i !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.length === csvData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(csvData.map((_, index) => index));
    }
  };

  const fieldOptions = [
    { label: "Clinician", value: "owner" },
    { label: "Patient ID", value: "patientId" },
    { label: "Description", value: "description" },
    { label: "Tags", value: "tags" },
    { label: "Date", value: "date" },
    { label: "Time", value: "time" },
    { label: "Not included", value: "null" },
  ];

  const handleFieldChange = (key, value) => {
    const selectedOption = fieldOptions.find(
      (option) => option.value === value
    );
    const label = selectedOption ? selectedOption.label : value;
    setSelectedFields((prevSelectedFields) => ({
      ...prevSelectedFields,
      [key]: value,
    }));

    setSelectedFieldData((prevSelectedFieldData) => {
      const updatedFieldData = csvData.map((row, rowIndex) => {
        const newRow = { ...prevSelectedFieldData[rowIndex] };
        newRow[label] = row[key] || "";
        return newRow;
      });

      return updatedFieldData;
    });
  };

  const handleDataChange = (rowIndex, header, value) => {
    setSelectedFieldData((prevSelectedFieldData) => {
      const updatedFieldData = [...prevSelectedFieldData];
      updatedFieldData[rowIndex] = {
        ...updatedFieldData[rowIndex],
        [header]: value,
      };
      return updatedFieldData;
    });
    setValidationError("");
  };

  const resetModalState = () => {
    setSelectedFieldData([]);
    setSelectedRows([]);
    setSelectedFields({});
  };

  const __getSessionsRef = (entityId, isOrgUser) => {
    const collectionName = isOrgUser ? "organizations" : "usersV2";
    return collection(db, `${collectionName}/${entityId}/sessions/`);
  };

  function getNewGlobalId(entityId, date) {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint().toString();
    const slicedFingerpring = fingerprint.slice(0, 8);
    const first2 = entityId.slice(0, 5);
    const randomSeconds = Math.floor(Math.random() * 60);
    const randomMilliseconds = Math.floor(Math.random() * 1000);

    const datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "_" +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + randomSeconds).slice(-2) +
      ":" +
      ("0" + randomMilliseconds).slice(-3);

    return (
      "SESSION" +
      "_WEB_" +
      `${first2}` +
      "-" +
      slicedFingerpring +
      "_" +
      datestring
    );
  }

  async function createSession(
    entityId,
    isOrgUser,
    sessionData,
    updateLastDeviceSyncNeeded = true
  ) {
    const sessionsRef = __getSessionsRef(entityId, isOrgUser);
    const sessionGlobalId = getNewGlobalId(entityId, sessionData.createdAt);
    if (!sessionData.patientGlobalId) {
      throw new Error("Patient ID is required to create a session");
    }
    if (!sessionData.imageGlobalIds) {
      sessionData.imageGlobalIds = [];
    }
    if (!sessionData.videoGlobalIds) {
      sessionData.videoGlobalIds = [];
    }
    sessionData.tags = sessionData.tags ? sessionData.tags : [];

    await setDoc(doc(sessionsRef, sessionGlobalId), {
      ...sessionData,
      globalId: sessionGlobalId,
      deleted: false,
    });

    if (updateLastDeviceSyncNeeded)
      await updateLastDeviceSync(entityId, isOrgUser);
    return sessionGlobalId;
  }

  const createNewSessions = async () => {
    let hasError = false;
    setValidationError("");

    for (const index of selectedRows) {
      const row = selectedFieldData[index] || {};
      const patientId = row["Patient ID"] ? row["Patient ID"] : "";
      const description = row["Description"] || "";
      const tagsSelected = Array.isArray(row["Tags"])
        ? row["Tags"]
        : row["Tags"]
        ? row["Tags"].split(",")
        : [];
      // const tagsSelected = row["Tags"] ? row["Tags"].split(",") : []; // Split tags string into an array
      const dateStr = row["Date"] ? row["Date"] : "";
      const timeStr = row["Time"] ? row["Time"] : "";
      const ownerName = row["Clinician"];
      let owner;

      if (ownerName === "Unassigned") {
        owner = "";
      } else {
        const matchedOwner = ownerIds.find((o) => o.name === ownerName);
        owner = matchedOwner ? matchedOwner.id : "";
      }
      let date = parseDate(dateStr);
      let time = parseTime(timeStr);

      if (!date) {
        date = new Date();
      }
      if (!time) {
        time = new Date();
      }

      const dateTimeStr = `${convertToISO(date)}T${convertTimeToISO(time)}:00`;
      const dateTime = new Date(dateTimeStr);

      const canonicalId = getCanonicalPatientId(patientId);
      const duplicatedPatient = Object.entries(patientIdDuplicate).find(
        ([_, value]) => value === canonicalId
      );

      if (!dateStr || !timeStr || !patientId) {
        setValidationError("Date, Time, and Patient ID must be selected.");
        hasError = true;
        break;
      }
      let sessionId;

      if (!duplicatedPatient) {
        const patientGlobalId = await firestoreRepository.createPatient(
          entityId,
          isOrgUser,
          patientId,
          false
        );
        const sessionData = {
          description: description,
          tags: tagsSelected,
          patientGlobalId: patientGlobalId,
          createdAt: dateTime,
          modifiedAt: dateTime,
          owner: owner,
        };
        sessionId = await createSession(entityId, isOrgUser, sessionData, true);
      } else {
        const [duplicatedPatientGlobalId, _] = duplicatedPatient;
        await firestoreRepository.updatePatient(
          entityId,
          isOrgUser,
          duplicatedPatientGlobalId,
          patientId,
          false
        );
        const sessionData = {
          description: description,
          tags: tagsSelected,
          patientGlobalId: duplicatedPatientGlobalId,
          createdAt: dateTime,
          modifiedAt: dateTime,
          owner: owner,
        };
        sessionId = await createSession(entityId, isOrgUser, sessionData, true);
      }
      if (!hasError) {
        setActiveStep(0);
        resetModalState();
        setOpen(false);
        handleCloseSave();
      }
    }
  };

  const convertToISO = (date) => {
    if (!(date instanceof Date) || isNaN(date) || null) return "";
    else {
      return format(date, "yyyy-MM-dd");
    }
  };

  const parseDate = (value) => {
    const parsedDate = Date.parse(value);
    return isNaN(parsedDate) ? null : new Date(parsedDate);
  };

  const convertTimeToISO = (time) => {
    if (!(time instanceof Date) || isNaN(time)) return "";
    return format(time, "HH:mm");
  };

  const parseTime = (timeString) => {
    const formats = [
      "HH:mm", // 24-hour format (e.g., "14:30")
      "h:mm a", // 12-hour format with AM/PM (e.g., "2:30 PM")
      "HH:mm:ss", // 24-hour with seconds (e.g., "14:30:45")
      "h:mm:ss a", // 12-hour with seconds and AM/PM (e.g., "2:30:45 PM")
    ];

    for (let formatString of formats) {
      const parsedTime = parse(timeString, formatString, new Date(), {
        locale: enUS,
      });

      if (isValid(parsedTime)) {
        return parsedTime;
      }
    }

    return null;
  };

  return (
    <>
      <div>
        {isOrgUser ? (
          <Tooltip
            title={
              isAllowedToWrite
                ? ""
                : "You do not have permission to perform this action"
            }
          >
            <button
              className="button_no_style"
              style={{
                marginRight: "1em",
                boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
                borderRadius: "24px",
                padding: "0px 20px",
                color: isAllowedToWrite ? "initial" : "#999999",
              }}
              disabled={!isAllowedToWrite}
              onClick={() => isAllowedToWrite && setOpen(true)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <img src={importPatient} style={{ width: "24px" }} />
                </IconButton>

                <div style={{ fontSize: "16px" }}>Import</div>
              </div>
            </button>
          </Tooltip>
        ) : (
          <button
            className="button_no_style"
            style={{
              marginRight: "1em",
              boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
              borderRadius: "24px",
              padding: "0px 20px",
              color: "#999999",
              backgroundColor: "#EFEFEF",
            }}
            onClick={() => setOpenBusiness(true)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <img src={importPatient} style={{ width: "24px" }} />
              </IconButton>

              <div style={{ fontSize: "16px" }}>Import</div>
            </div>
          </button>
        )}
        <BootstrapDialog
          onClose={() => setOpenBusiness(false)}
          aria-labelledby="customized-dialog-title"
          open={openBusiness}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={() => setOpenBusiness(false)}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div>
                  <div
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      boxShadow: "0px 2px 4px 0px #32324714",
                      padding: "20px",
                    }}
                  >
                    Empower Your Team with Multi-User Access!{" "}
                  </div>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      sx={{
                        order: { xs: 2, md: 1 }, // Change order for xs and md devices
                        backgroundColor: "#9747FF",
                        padding: "20px",
                        color: "white",
                        fontSize: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          width: "90%",
                          margin: "0 auto",
                        }}
                      >
                        <p>
                          Designed for organizations, offering seamless{" "}
                          <b>multi-user access and customizable permissions.</b>
                        </p>
                        <p>
                          Experience simplified data management tailored for
                          your team's needs!
                        </p>{" "}
                        <a
                          href="mailto:maria@customsurgical.co"
                          style={{
                            backgroundColor: "white",
                            color: "#9747FF",
                            border: "none",
                            borderRadius: "10px",
                            padding: "10px 0px",
                            cursor: "pointer",
                            fontWeight: "bold",
                            width: "90%",
                            marginTop: "5em",
                            textAlign: "center",
                            display: "inline-block",
                            textDecoration: "none",
                          }}
                        >
                          Join the Pilot Program!
                        </a>
                      </div>{" "}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      sx={{
                        order: { xs: 1, md: 2 },
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "1em",
                          marginBottom: "2em",
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#8F4FFF",
                        }}
                      >
                        <span>MicroREC Connect</span>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          Business Tier
                        </span>
                      </div>
                      <img src={ind_import} style={{ maxWidth: "100%" }}></img>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={() => setOpenSave(false)}
          aria-labelledby="customized-dialog-title"
          open={openSave}
          maxWidth={"sm"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={() => setOpenSave(false)}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div style={{ padding: "20px" }}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginBottom: "1em",
                    }}
                  >
                    Save Progress?
                  </div>
                  <div>
                    Would you like to save your progress before exiting? You can
                    resume from where you left off when you return.
                  </div>
                </div>
                <hr style={{ border: "1px solid #EFEFEF" }}></hr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2em",
                    marginBottom: "1em",
                  }}
                >
                  <button
                    style={{
                      width: "fit-content",
                      border: "1px solid #D9D9D9",
                      backgroundColor: "white",
                      padding: "10px 35px",
                      color: "#3D3838",
                      marginRight: "1em",
                    }}
                    className="button"
                    onClick={handleCloseSave}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      width: "fit-content",
                      backgroundColor: "#F1E7FF",
                      padding: "10px 35px",
                      color: "#3D3838",
                      marginRight: "1em",
                    }}
                    className="button"
                    onClick={handleModalClose}
                  >
                    Clear&Exit
                  </button>
                  <button
                    style={{
                      width: "fit-content",
                      backgroundColor: "#8F4FFF",
                      padding: "10px 35px",
                      marginRight: "1em",
                    }}
                    className="button"
                    onClick={() => {
                      setOpenSave(false);
                      setOpen(false);
                    }}
                  >
                    Save&Exit
                  </button>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"100%"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Create New Sessions
                    </div>
                  </div>
                </div>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        sx={{
                          "& .MuiStepLabel-label": {
                            color: activeStep === index ? "#8F4FFF" : "inherit",
                            fontWeight:
                              activeStep === index ? "600" : "inherit",
                          },
                          "& .MuiStepIcon-root": {
                            color: activeStep === index ? "#8F4FFF" : "inherit",
                          },
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "#8F4FFF",
                          },
                          "& .Mui-completed .MuiStepIcon-root": {
                            color: "#8F4FFF",
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {activeStep === 0 && (
                  <div style={{ padding: "20px" }}>
                    <div
                      style={{
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        padding: "5px",
                        marginTop: "1em",
                        marginBottom: "1em",

                        borderRadius: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div style={{ padding: "10px", fontSize: "14px" }}>
                        Example of the CSV file:{" "}
                      </div>
                      <TableContainer
                        style={{
                          borderRadius: "10px",
                          width: "90%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Table>
                          <TableHead className="table_header">
                            <TableRow>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Date (*)
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Time (*)
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Clinician
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Patient ID (*)
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Description
                              </TableCell>
                              <TableCell sx={{ fontWeight: "600" }}>
                                Tags
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>2023-12-15</TableCell>
                              <TableCell>14:30</TableCell>
                              <TableCell>John Doe</TableCell>
                              <TableCell>P12345</TableCell>
                              <TableCell>Routine check-up</TableCell>
                              <TableCell>follow-up</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>2023-12-16</TableCell>
                              <TableCell>10:00</TableCell>
                              <TableCell>John Smith</TableCell>
                              <TableCell>P23456</TableCell>
                              <TableCell>First consultation</TableCell>
                              <TableCell>cataract</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div style={{ padding: "10px", fontSize: "13px" }}>
                        (*) Make sure the file includes Patient ID, date and
                        time.
                      </div>
                    </div>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <div className="upload-div">
                        {isFocused || isDragAccept ? (
                          <img src={addImageActive}></img>
                        ) : isDragReject || !isAllowedToWrite ? (
                          <img src={addImageRejected}></img>
                        ) : (
                          <img src={addImage}></img>
                        )}
                        <div className="upload-p">Add csv</div>
                      </div>
                      <div>or drop file to upload</div>
                      <i className="upload-i">We currently support CSV.</i>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1em",
                        marginBottom: "0.5em",
                      }}
                    >
                      <button
                        style={{
                          width: "fit-content",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "white",
                          padding: "10px 35px",
                          color: "#3D3838",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={handleModalClose}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          width: "fit-content",
                          backgroundColor:
                            csvData.length === 0 ? "#D9D9D9" : "#8F4FFF",
                          padding: "10px 35px",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => {
                          if (csvData.length > 0) {
                            setActiveStep(1);
                          }
                        }}
                        disabled={csvData.length === 0}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 1 && (
                  <div style={{ padding: "20px" }}>
                    <Grid
                      container
                      style={{
                        borderRadius: "10px",
                        padding: "10px",
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                      }}
                    >
                      <div
                        style={{
                          color: "#797979",
                          marginBottom: "1em",
                          fontSize: "14px",
                        }}
                      >
                        Ensure columns from your file are mapped correctly to
                        session properties{" "}
                      </div>
                      <div
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={4} style={{ fontWeight: "500" }}>
                          Columns in your file{" "}
                        </Grid>
                        <Grid item xs={2.5} style={{ fontWeight: "500" }}>
                          CSV Data{" "}
                        </Grid>
                        <Grid item xs={1.5}>
                          {""}
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: "500" }}>
                          Session fields
                        </Grid>
                      </div>
                      <div
                        style={{ overflowY: "scroll", height: "38vh" }}
                        className="hide-scrollbar"
                      >
                        {Object.keys(csvData[0]).map((key) => (
                          <div
                            key={key}
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "2em",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            <Grid item xs={12} sm={4}>
                              <div
                                style={{
                                  backgroundColor: "#F0F0F2",
                                  borderRadius: "10px",
                                  padding: "10px 15px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "1em",
                                }}
                              >
                                <img src={addImage}></img>
                                <span style={{ marginLeft: "1em" }}>{key}</span>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={2.5}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "10px",
                                  boxShadow: "0px 2px 8px 0px #3A5C9D24",
                                  padding: "10px 15px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {csvData.slice(0, 1).map((row, index) => (
                                  <div key={index}>{row[key]}</div>
                                ))}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={1.5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <EastOutlined />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                size="small"
                                select
                                label="Select field"
                                fullWidth
                                variant="outlined"
                                style={{
                                  borderRadius: "10px",
                                }}
                                value={selectedFields[key] || ""}
                                onChange={(e) =>
                                  handleFieldChange(key, e.target.value)
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: "custom-label",
                                    focused: "custom-label-focused",
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: "custom-notchedOutline",
                                    focused: "custom-focused",
                                  },
                                }}
                              >
                                {fieldOptions
                                  .filter(
                                    (option) =>
                                      option.value === "null" ||
                                      !Object.values(selectedFields).includes(
                                        option.value
                                      ) ||
                                      selectedFields[key] === option.value
                                  )
                                  .map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1em",
                        marginBottom: "1em",
                      }}
                    >
                      <button
                        style={{
                          width: "fit-content",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "white",
                          padding: "10px 35px",
                          color: "#3D3838",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => setActiveStep(0)}
                      >
                        Back
                      </button>
                      <button
                        style={{
                          width: "fit-content",
                          backgroundColor: "#8F4FFF",
                          padding: "10px 35px",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => {
                          setActiveStep(2);
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div style={{ padding: "20px" }}>
                    <div
                      style={{
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        padding: "5px",
                        borderRadius: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "0.5em",
                      }}
                    >
                      <div style={{ padding: "10px", fontSize: "14px" }}>
                        Review the data, and update any field that is incorrect:
                      </div>
                      <TableContainer
                        style={{
                          borderRadius: "10px",
                          width: "95%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxHeight: "350px",
                          overflow: "auto",
                        }}
                      >
                        <Table style={{ minWidth: "1000px" }}>
                          <TableHead className="table_header">
                            <TableRow>
                              <TableCell style={{ width: "2%" }}>
                                <Checkbox
                                  checked={
                                    selectedRows.length === csvData.length
                                  }
                                  onChange={handleSelectAll}
                                  style={{ color: "#8F4FFF" }}
                                />
                              </TableCell>
                              {columnHeaders.map((header) => (
                                <TableCell
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "16px",
                                    paddingLeft: "25px",
                                    width:
                                      header === "Date" || header === "Time"
                                        ? "10%"
                                        : `${90 / columnHeaders.length}%`,
                                  }}
                                  key={header}
                                >
                                  {header}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {csvData.map((_, rowIndex) => (
                              <TableRow
                                key={rowIndex}
                                className="tableRowHover"
                              >
                                <TableCell style={{ width: "2%" }}>
                                  <Checkbox
                                    checked={selectedRows.includes(rowIndex)}
                                    onChange={() =>
                                      handleCheckboxChange(rowIndex)
                                    }
                                    style={{ color: "#8F4FFF" }}
                                  />
                                </TableCell>

                                {columnHeaders.map((header) => {
                                  const fieldKey = Object.keys(
                                    selectedFieldData[rowIndex] || {}
                                  ).find((key) => key === header);
                                  const value = fieldKey
                                    ? selectedFieldData[rowIndex][fieldKey]
                                    : "";
                                  return (
                                    <TableCell
                                      key={header}
                                      style={{
                                        width:
                                          header === "Date" || header === "Time"
                                            ? "10%"
                                            : `${90 / columnHeaders.length}%`,
                                      }}
                                    >
                                      {header === "Date" ? (
                                        <DatePicker
                                          selected={
                                            value ? parseDate(value) : null
                                          }
                                          onChange={(date) =>
                                            handleDataChange(
                                              rowIndex,
                                              header,
                                              date ? convertToISO(date) : ""
                                            )
                                          }
                                          dateFormat="yyyy-MM-dd"
                                          customInput={
                                            <TextField
                                              className="tableCellHover"
                                              variant="standard"
                                              size="small"
                                              InputProps={{
                                                disableUnderline: true,
                                              }}
                                              style={{ padding: "5px 10px" }}
                                            />
                                          }
                                        />
                                      ) : header === "Time" ? (
                                        <DatePicker
                                          selected={
                                            value ? parseTime(value) : null
                                          }
                                          onChange={(time) =>
                                            handleDataChange(
                                              rowIndex,
                                              header,
                                              time ? convertTimeToISO(time) : ""
                                            )
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={15}
                                          timeCaption="Time"
                                          dateFormat="HH:mm"
                                          customInput={
                                            <TextField
                                              value={value}
                                              className="tableCellHover"
                                              variant="standard"
                                              size="small"
                                              InputProps={{
                                                disableUnderline: true,
                                              }}
                                              style={{ padding: "5px 10px" }}
                                            />
                                          }
                                        />
                                      ) : header === "Patient ID" ? (
                                        <Autocomplete
                                          freeSolo
                                          options={patientIds.map(
                                            (patient) => patient.patientId
                                          )}
                                          value={value || ""}
                                          onChange={(e, newValue) =>
                                            handleDataChange(
                                              rowIndex,
                                              header,
                                              newValue
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="tableCellHover arrow-on-hover"
                                              variant="standard"
                                              size="small"
                                              InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                              }}
                                              InputLabelProps={{
                                                ...params.InputLabelProps,
                                                shrink: true,
                                              }}
                                              style={{ padding: "5px 10px" }}
                                            />
                                          )}
                                        />
                                      ) : header === "Clinician" ? (
                                        <TextField
                                          select
                                          value={
                                            ownerIds.some(
                                              (owner) =>
                                                owner.name === value ||
                                                owner.id === value
                                            )
                                              ? ownerIds.find(
                                                  (owner) =>
                                                    owner.name === value ||
                                                    owner.id === value
                                                ).id
                                              : "Unassigned"
                                          }
                                          onChange={(e) =>
                                            handleDataChange(
                                              rowIndex,
                                              header,
                                              e.target.value || "Unassigned"
                                            )
                                          }
                                          className="tableCellHover arrow-on-hover"
                                          variant="standard"
                                          size="small"
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          SelectProps={{
                                            classes: {
                                              select: "no-background",
                                            },
                                          }}
                                          style={{
                                            padding: "5px 10px",
                                            width: "100%",
                                          }}
                                        >
                                          {ownerIds.map((owner) => (
                                            <MenuItem
                                              key={owner.id}
                                              value={owner.id}
                                            >
                                              {owner.name}
                                            </MenuItem>
                                          ))}
                                          <MenuItem value="Unassigned">
                                            Unassigned
                                          </MenuItem>
                                        </TextField>
                                      ) : header === "Tags" ? (
                                        <Autocomplete
                                          multiple
                                          freeSolo
                                          options={tags}
                                          value={
                                            typeof value === "string"
                                              ? value
                                                  .split(",")
                                                  .filter(
                                                    (tag) => tag.trim() !== ""
                                                  )
                                              : value
                                          }
                                          onChange={(e, newValue) =>
                                            handleDataChange(
                                              rowIndex,
                                              header,
                                              newValue.filter(
                                                (tag) => tag.trim() !== ""
                                              )
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="tableCellHover arrow-on-hover"
                                              variant="standard"
                                              size="small"
                                              InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                style: {
                                                  fontSize: "inherit",
                                                  background: "none",
                                                  border: "none",
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                },
                                              }}
                                              InputLabelProps={{
                                                ...params.InputLabelProps,
                                                shrink: true,
                                              }}
                                              style={{
                                                padding: "5px 10px",
                                                maxWidth: "200px",
                                                width: "95%",
                                              }}
                                            />
                                          )}
                                        />
                                      ) : (
                                        <TextField
                                          value={value}
                                          onChange={(e) =>
                                            handleDataChange(
                                              rowIndex,
                                              header,
                                              e.target.value
                                            )
                                          }
                                          className="tableCellHover"
                                          variant="standard"
                                          size="small"
                                          multiline={header === "Description"}
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          style={{ padding: "5px 10px" }}
                                        />
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    {validationError && (
                      <div style={{ color: "red", textAlign: "right" }}>
                        {validationError}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1em",
                        marginBottom: "0em",
                      }}
                    >
                      <button
                        style={{
                          width: "fit-content",
                          border: "1px solid #D9D9D9",
                          backgroundColor: "white",
                          padding: "10px 35px",
                          color: "#3D3838",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => setActiveStep(1)}
                      >
                        Back
                      </button>
                      <button
                        style={{
                          width: "fit-content",
                          backgroundColor: validationError
                            ? "#BDBDBD"
                            : "#8F4FFF",
                          padding: "10px 35px",
                          marginRight: "1em",
                        }}
                        className="button"
                        onClick={() => {
                          createNewSessions();
                        }}
                      >
                        Create {selectedRows.length} sessions
                      </button>
                    </div>
                  </div>
                )}
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CSVUpload;

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository from "../../repositories/firestoreRepository";
import "../dashboard/Dashboard.css";
import { UploadContext } from "../App";
import patient from "../dashboard/patient.svg";
import "react-calendar/dist/Calendar.css";

import { serverTimestamp, Timestamp } from "firebase/firestore";
import { ConstructionOutlined, EditOutlined } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #8f4fff;
    }
  }
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 10px;
    &.Mui-focused fieldset {
      border-color: #8f4fff;
    }
  }
`;

const ModalEditPatient = ({
  entityId,
  isOrgUser,
  wrtPermissions,
  firstNameExist,
  lastNameExist,
  patientIdExist,
  birthDateExist,
  genderExist,
  reloadCallback,
  allPatients,
}) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [patientIdDuplicate, setPatientIdDuplicate] = useState({});
  const [firstName, setFirstName] = useState(firstNameExist);
  const [lastName, setLastName] = useState(lastNameExist);
  const [patientId, setPatientId] = useState(patientIdExist);
  const [birthDate, setBirthDate] = useState(new Date(birthDateExist));
  const [originalData, setOriginalData] = useState({
    firstName: firstNameExist,
    lastName: lastNameExist,
    patientId: patientIdExist,
  });
  const [gender, setGender] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPatientId, setErrorPatientId] = useState("");
  const [errorBirthDate, setErrorBirthDate] = useState("");
  const location = useLocation();

  const getGenderIndex = (gender) => {
    switch (gender) {
      case "unknown":
      case "Unknown":
        return 0;
      case "male":
      case "Male":
        return 1;
      case "female":
      case "Female":
        return 2;
      case "other":
      case "Other":
        return 9;
      default:
        return 0;
    }
  };

  const getGenderText = (index) => {
    switch (index) {
      case 0:
        return "unknown";
      case 1:
        return "male";
      case 2:
        return "female";
      case 9:
        return "other";
      default:
        return "unknown";
    }
  };

  const parseDateString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${day}-${month}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      var tempPatients = {};
      allPatients.forEach((patient) => {
        const globalId = patient.globalId;
        const canonicalId = patient.canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      setPatientIdDuplicate(tempPatients);
      const gender = getGenderIndex(genderExist);
      const formattedDate = parseDateString(birthDateExist);
      setBirthDate(formattedDate);
      setGender(getGenderText(gender));
    };
    fetchData();
  }, []);

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    const minDate = new Date("1900-01-01");
    const maxDate = new Date();
    const selectedDate = new Date(value);
    if (selectedDate < minDate || selectedDate > maxDate) {
      setErrorBirthDate("The date of birth is invalid.");
    } else {
      setErrorBirthDate("");
      setBirthDate(value);
    }
  };

  const handleTextAreaChangeFirstName = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleBlurFirstName = () => {
    if (!firstName) {
      setErrorFirstName("First Name is required.");
    } else {
      setErrorFirstName("");
    }
  };

  const handleTextAreaChangeLastName = (e) => {
    const value = e.target.value;
    setLastName(value);
  };

  const handleBlurLastName = () => {
    if (!lastName) {
      setErrorLastName("Last name is required.");
    } else {
      setErrorLastName("");
    }
  };

  const handleTextAreaChangePatientId = (e) => {
    setPatientId(e.target.value);
  };

  const handleBlurPatientId = () => {
    if (!patientId) {
      setErrorPatientId("Patient ID is required.");
    } else {
      setErrorPatientId("");
    }
  };

  const cleanData = () => {
    setFirstName(originalData.firstName);
    setLastName(originalData.lastName);
    setPatientId(originalData.patientId);
    const gender = getGenderIndex(genderExist);
    const formattedDate = parseDateString(birthDateExist);
    setBirthDate(formattedDate);
    setGender(getGenderText(gender));

    setErrorFirstName("");
    setErrorLastName("");
    setErrorPatientId("");
    setErrorBirthDate("");
  };

  const handleModalClose = () => {
    handleClose();
    cleanData();
  };

  const createNewPatient = async () => {
    const canonicalId = getCanonicalPatientId(patientId);
    const duplicatedPatient = Object.entries(patientIdDuplicate).find(
      ([_, value]) => value === canonicalId
    );
    const genderIndex = getGenderIndex(gender);
    const birthDateTimestamp = Timestamp.fromDate(new Date(birthDate));
    const validateTimestamp = isNaN(birthDateTimestamp.seconds)
      ? null
      : birthDateTimestamp;

    if (!firstName) {
      setErrorFirstName("First Name is required.");
      return;
    } else if (!lastName) {
      setErrorLastName("Last Name is required.");
      return;
    } else if (!patientId) {
      setErrorPatientId("Patient ID is required.");
      return;
    }

    if (!duplicatedPatient) {
      const patientData = {
        firstName: firstName,
        lastName: lastName,
        birthDate: validateTimestamp,
        genderIdx: genderIndex,
      };

      await firestoreRepository.createPatient(
        entityId,
        isOrgUser,
        patientId,
        false,
        patientData
      );
    } else {
      const [duplicatedPatientGlobalId, _] = duplicatedPatient;

      const patientData = {
        firstName: firstName,
        lastName: lastName,
        birthDate: validateTimestamp,
        genderIdx: genderIndex,
      };

      await firestoreRepository.updatePatient(
        entityId,
        isOrgUser,
        duplicatedPatientGlobalId,
        patientId,
        false,
        patientData
      );
    }

    handleModalClose();
    reloadCallback();
  };

  return (
    <>
      <div>
        {/* <button
          className="button_no_style"
          style={{
            marginRight: "1em",
            boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
            borderRadius: "24px",
            padding: "0px 20px",
            backgroundColor: "#8F4FFF",
          }}
          onClick={() => setOpen(true)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          > */}
        {location.pathname.includes("/sessions") ? (
          <button
            style={{
              width: "fit-content",
              backgroundColor: "#8F4FFF",
              padding: "10px 35px",
              marginRight: "1em",
            }}
            className="button"
            onClick={() => setOpen(true)}
          >
            Edit
          </button>
        ) : (
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => setOpen(true)}
          >
            <EditOutlined />
          </IconButton>
        )}

        {/* <div style={{ color: "white", fontSize: "16px" }}>Edit Patient</div>
          </div>
        </button> */}
        <BootstrapDialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleModalClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Edit Patient
                    </div>
                  </div>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                      padding: "30px",
                      paddingTop: "0.7em",
                    }}
                  >
                    <Grid item xs={12}>
                      <label className="label">First Name (*)</label>
                      <StyledTextField
                        onChange={handleTextAreaChangeFirstName}
                        onBlur={handleBlurFirstName}
                        value={firstName}
                        multiline
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        InputProps={{
                          style: {
                            height: "100%",
                            resize: "none",
                          },
                        }}
                        error={!!errorFirstName}
                        helperText={errorFirstName}
                      />
                      <label className="label">Last Name (*)</label>
                      <StyledTextField
                        onChange={handleTextAreaChangeLastName}
                        onBlur={handleBlurLastName}
                        value={lastName}
                        multiline
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        InputProps={{
                          style: {
                            height: "100%",
                            resize: "none",
                          },
                        }}
                        error={!!errorLastName}
                        helperText={errorLastName}
                      />
                      <label className="label">Patient ID (*)</label>
                      <Autocomplete
                        options={allPatients}
                        getOptionLabel={(option) => {
                          // Ensure that the option is not displayed if patientId is empty or undefined
                          if (typeof option === "string") {
                            return option;
                          }
                          return option.patientId || "";
                        }}
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            // Handle case when "Create Patient: value" option is selected
                            if (newValue.startsWith("Create Patient: ")) {
                              setPatientId(newValue.slice(16)); // Strip "Create Patient: " prefix
                            } else {
                              setPatientId(newValue); // Use value directly if no prefix
                            }
                          } else if (
                            newValue &&
                            typeof newValue === "object" &&
                            newValue.patientId
                          ) {
                            setPatientId(newValue.patientId); // Set patient ID directly from selected object
                          } else {
                            setPatientId("");
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setPatientId(newInputValue);
                        }}
                        value={
                          allPatients?.find(
                            (option) => option.patientId === patientId
                          ) || { patientId: patientId }
                        }
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            onBlur={handleBlurPatientId}
                            error={!!errorPatientId}
                            helperText={errorPatientId}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {typeof option === "string"
                              ? option
                              : option.patientId}
                          </li>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = options.filter((option) => {
                            // Exclude options where patientId is empty or undefined
                            return (
                              option?.patientId &&
                              option.patientId
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            );
                          });

                          if (
                            params.inputValue !== "" &&
                            !filtered.some(
                              (option) =>
                                option.patientId.toLowerCase() ===
                                params.inputValue.toLowerCase()
                            )
                          ) {
                            filtered.push(
                              `Create Patient: ${params.inputValue}`
                            );
                          }

                          const selectedOptions = Array.isArray(params.value)
                            ? params.value.map((option) => option)
                            : [];
                          const combinedOptions = [
                            ...new Set([...selectedOptions, ...filtered]),
                          ];

                          return combinedOptions.slice(0, 5);
                        }}
                      />
                      <label className="label">Date of birth</label>{" "}
                      <div>
                        <StyledTextField
                          onChange={handleDateChange}
                          value={birthDate}
                          type="date"
                          style={{
                            width: "100%",
                            resize: "none",
                            display: "flex",
                            marginTop: "0.3em",
                            marginBottom: "0.3em",
                          }}
                          InputProps={{
                            style: {
                              height: "100%",
                              resize: "none",
                            },
                          }}
                          inputProps={{
                            min: "1900-01-01",
                            max: new Date().toISOString().split("T")[0],
                          }}
                          error={!!errorBirthDate}
                          helperText={errorBirthDate}
                        />
                      </div>
                      <label className="label">Gender</label>
                      <FormControl fullWidth>
                        <Select
                          value={gender}
                          onChange={handleGenderChange}
                          sx={{
                            width: "100%",
                            resize: "none",
                            display: "flex",
                            marginTop: "0.3em",
                            marginBottom: "0.3em",
                            borderRadius: "10px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#8F4FFF",
                            },
                          }}
                        >
                          <MenuItem value="unknown">Unknown</MenuItem>
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        width: "fit-content",
                        border: "1px solid #D9D9D9",
                        backgroundColor: "white",
                        padding: "10px 35px",
                        color: "#3D3838",
                        marginRight: "1em",
                      }}
                      className="button"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </button>
                    <button
                      style={{
                        width: "fit-content",
                        backgroundColor: "#8F4FFF",
                        padding: "10px 35px",
                        marginRight: "1em",
                      }}
                      className="button"
                      onClick={() => {
                        createNewPatient();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ModalEditPatient;

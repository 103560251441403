import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, TextField, Tooltip } from "@mui/material";
import "../dashboard/Dashboard.css";
import "react-calendar/dist/Calendar.css";
import patientIcon from "../../images/dashboard/patient.svg";
import patientActive from "../../images/dashboard/patientActive.svg";
import ModalEditPatient from "./ModalEditPatient";
import { set } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 10px;
    background-color: #f0f0f2;
    border: none; /* Remove border */
    &:hover fieldset {
      border: none; /* Remove border on hover */
    }
    &.Mui-focused fieldset {
      border: none; /* Remove border on focus */
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none; /* Remove border */
  }
`;

const ModalInfoPatient = ({
  entityId,
  isOrgUser,
  wrtPermissions,
  firstName,
  lastName,
  patientId,
  birthDate,
  gender,
  allPatients,
  reloadCallback,
}) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [birthDateFormat, setBirthDateFormat] = useState("");
  const [genderFormat, setGenderFormat] = useState("");
  const [firstNameExist, setFirstNameExist] = useState("");
  const [lastNameExist, setLastNameExist] = useState("");
  const [patientIdExist, setPatientIdExist] = useState("");

  const getGenderText = (index) => {
    switch (index) {
      case 0:
        return "Unknown";
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 9:
        return "Other";
      default:
        return "";
    }
  };

  const parseDateString = (timestamp) => {
    if (!timestamp) {
      return "";
    } else {
      const date = timestamp.toDate();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}/${month}/${year}`;
    }
  };

  useEffect(() => {
    const formattedDate = parseDateString(birthDate);
    if (formattedDate !== birthDateFormat) {
      setBirthDateFormat(formattedDate);
    }
    const genderText = getGenderText(gender);
    if (genderText !== genderFormat) {
      setGenderFormat(genderText);
    }
    if (firstName !== firstNameExist) {
      setFirstNameExist(firstName);
    }
    if (lastName !== lastNameExist) {
      setLastNameExist(lastName);
    }
    if (patientId !== patientIdExist) {
      setPatientIdExist(patientId);
    }
  }, [birthDate, gender, firstName, lastName, patientId]);

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <>
      <div>
        <Tooltip title="Check patient details">
          <IconButton
            aria-label="edit"
            style={{ marginLeft: "10px" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setOpen(true)}
          >
            <img src={isHovered ? patientActive : patientIcon} />
          </IconButton>
        </Tooltip>
        <BootstrapDialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleModalClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Patient
                    </div>
                  </div>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                      padding: "30px",
                      paddingTop: "0.7em",
                    }}
                  >
                    <Grid item xs={12}>
                      <label className="label">First Name (*)</label>
                      <StyledTextField
                        value={firstName}
                        multiline
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        InputProps={{
                          readOnly: true,
                          style: {
                            height: "100%",
                            resize: "none",
                          },
                        }}
                      />
                      <label className="label">Last Name (*)</label>
                      <StyledTextField
                        value={lastName}
                        multiline
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        InputProps={{
                          readOnly: true,
                          style: {
                            height: "100%",
                            resize: "none",
                          },
                        }}
                      />
                      <label className="label">Patient ID (*)</label>
                      <StyledTextField
                        value={patientId}
                        multiline
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        InputProps={{
                          readOnly: true,
                          style: {
                            height: "100%",
                            resize: "none",
                          },
                        }}
                      />
                      <label className="label">Date of birth</label>{" "}
                      <div>
                        <StyledTextField
                          value={birthDateFormat}
                          style={{
                            width: "100%",
                            resize: "none",
                            display: "flex",
                            marginTop: "0.3em",
                            marginBottom: "0.3em",
                          }}
                          InputProps={{
                            readOnly: true,
                            style: {
                              height: "100%",
                              resize: "none",
                            },
                          }}
                        />
                      </div>
                      <label className="label">Gender</label>
                      <StyledTextField
                        value={genderFormat}
                        multiline
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "0.3em",
                          marginBottom: "0.3em",
                        }}
                        InputProps={{
                          readOnly: true,
                          style: {
                            height: "100%",
                            resize: "none",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        width: "fit-content",
                        border: "1px solid #D9D9D9",
                        backgroundColor: "white",
                        padding: "10px 35px",
                        color: "#3D3838",
                        marginRight: "1em",
                      }}
                      className="button"
                      onClick={handleModalClose}
                    >
                      Close
                    </button>
                    {/* <button
                      style={{
                        width: "fit-content",
                        backgroundColor: "#8F4FFF",
                        padding: "10px 35px",
                        marginRight: "1em",
                      }}
                      className="button"
                      //   onClick={() => {
                      //     createNewPatient();
                      //   }}
                    >
                      Edit
                    </button> */}
                    <ModalEditPatient
                      entityId={entityId}
                      isOrgUser={isOrgUser}
                      wrtPermissions={wrtPermissions}
                      firstNameExist={firstName}
                      lastNameExist={lastName}
                      patientIdExist={patientId}
                      birthDateExist={birthDateFormat}
                      genderExist={genderFormat}
                      reloadCallback={() => reloadCallback()}
                      allPatients={allPatients}
                    />
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ModalInfoPatient;

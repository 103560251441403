import React, { useState, useEffect, useRef } from "react";
import { useAuth, getAuthData } from "../../context/AuthContext";
import {
  CircularProgress,
  IconButton,
  Paper,
  Collapse,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Tooltip,
  TableSortLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import firestoreRepository, {
  listenDeviceSyncChanges,
} from "../../repositories/firestoreRepository";
import Layout from "../Layout";
import "./Dashboard.css";
import FreeUser from "./free";
import cloudFunctionRepository from "../../repositories/cloudFunctionRepository";
import SessionCreation from "../common/ModalSessionCreation";
import FreeOrg from "./free_org";
import UploadPatientCSV from "../modals/uploadPatient";
import PatientCreation from "../common/ModalPatientCreation";
import importPatient from "./import.svg";
import "../../style/patient.css";
import { Add, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import ModalEditPatient from "../common/ModalEditPatient";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #8f4fff;
    }
  }
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 30px;
    width: 350px;
    height: 40px;
    &.Mui-focused fieldset {
      border-color: #8f4fff;
    }
  }
`;

export default function Dashboard() {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState([]);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const lastDeviceSyncRef = useRef();
  const setLastDeviceSync = (data) => {
    lastDeviceSyncRef.current = data;
  };
  const [subscriptionData, setSubscriptionData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [authData, setAuthData] = useState({});
  const entityId = useRef(null);
  const isOrgUser = useRef(null);
  const [patients, setPatients] = useState([]);
  const [rows, setRows] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [techData, setTechData] = useState([]);
  const apiRef = useRef(null);
  const [openPatients, setOpenPatients] = useState({});
  const [allTags, setAllTags] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [orgData, setOrgData] = useState({});
  const wrtPermissions = useRef(null);
  const genderMap = {
    0: "Unknown",
    1: "Male",
    2: "Female",
    9: "Other",
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("patientName");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (isOrgUser.current === true) {
        const orgData = await loadOrgData(entityId.current);
        setLastDeviceSync(orgData.lastDeviceSync);
        setLargestSubscriptionId(orgData.stripeSubscriptionId || 0);

        const claims = await firestoreRepository.getUserClaims(currentUser.uid);
        const orgDataDetails = await firestoreRepository.getOrgData(
          claims.orgId
        );

        const currentUserData = orgDataDetails.users[currentUser.uid];
        if (currentUserData) {
          wrtPermissions.current = currentUserData.pWrt;
        }

        const ownersAndTechs = Object.keys(orgDataDetails.users).map(
          (userId) => ({
            id: userId,
            name: orgDataDetails.users[userId].name,
            clrIdx: orgDataDetails.users[userId].clrIdx,
            roleId: orgDataDetails.users[userId].roleId,
          })
        );

        setOrgData({
          ownerData: ownersAndTechs?.filter((user) => user.roleId === 1),
          techData: ownersAndTechs?.filter((user) => user.roleId === 0),
        });
      } else if (isOrgUser.current === false) {
        const userData = await loadUserData(currentUser.uid);
        setUserData(userData);
        setLastDeviceSync(userData.lastDeviceSync);
        setLargestSubscriptionId(
          Math.max(
            userData.stripeSubscriptionId || 0,
            userData.iosSubscriptionId || 0,
            userData.androidSubscriptionId || 0,
            userData.premiumAndroid ? 2 : 0,
            userData.premiumIOS ? 2 : 0
          )
        );
      }
    };

    getAuthData()
      .then((data) => {
        setAuthData(data);
        if (!data.claims.orgId) {
          entityId.current = currentUser.uid;
          isOrgUser.current = false;
        } else {
          entityId.current = data.claims.orgId;
          isOrgUser.current = true;
        }

        loadSubscriptionData(data.token).then((subscriptionData) => {
          setSubscriptionData(subscriptionData);
        });
        loadAllTags(entityId.current, isOrgUser.current).then((tags) => {
          setAllTags(tags);
        });
        loadAllPatients(entityId.current, isOrgUser.current).then(
          (patients) => {
            setAllPatients(patients);
          }
        );

        loadAllSessions(entityId.current, isOrgUser.current).then(
          (sessions) => {
            setAllSessions(sessions);
          }
        );
        fetchData();
      })
      .catch((error) => {
        console.error("Error getting auth data: ", error);
      });
  }, [currentUser.uid]);

  useEffect(() => {
    if (orgData.ownerData && orgData.techData) {
      fetchPatients();
    }
  }, [orgData]);

  const togglePatientOpen = (patientId) => {
    setOpenPatients((prevOpenPatients) => ({
      ...prevOpenPatients,
      [patientId]: !prevOpenPatients[patientId],
    }));
  };

  const loadSubscriptionData = async (authToken) => {
    try {
      const subscriptionData =
        await cloudFunctionRepository.getSubscriptionData(authToken);
      return subscriptionData;
    } catch (error) {
      console.error("Error getting subscription data: ", error);
    }
  };

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const loadOrgData = async (orgId) => {
    const data = await firestoreRepository.getOrgData(orgId);
    return data;
  };

  const loadAllPatients = async (entityId, isOrgUser) => {
    const patientsData = await firestoreRepository.getAllPatients(
      entityId,
      isOrgUser
    );
    return patientsData;
  };

  const loadAllSessions = async (entityId, isOrgUser) => {
    const sessionsData = await firestoreRepository.getAllSessions(
      entityId,
      isOrgUser
    );
    return sessionsData;
  };

  const loadAllTags = async (entityId, isOrgUser) => {
    const tags = await firestoreRepository.getAllTags(entityId, isOrgUser);
    return tags;
  };

  const fetchPatients = async () => {
    const getAllPatients = await loadAllPatients(
      entityId.current,
      isOrgUser.current
    );
    const getAllSessions = await loadAllSessions(
      entityId.current,
      isOrgUser.current
    );

    const patientSessionsMap = getAllSessions.reduce((acc, session) => {
      if (!acc[session.patientGlobalId]) {
        acc[session.patientGlobalId] = [];
      }
      const owner = orgData.ownerData?.find(
        (owner) => owner.id === session.owner
      );
      const tech = orgData.techData?.find(
        (tech) => tech.id === session.technician
      );

      acc[session.patientGlobalId].push({
        ...session,
        ownerName: owner?.name || "Unassigned",
        techName: tech?.name || "Unassigned",
      });

      return acc;
    }, {});

    const formattedRows = getAllPatients
      .filter((patient) => patient.patientId) // Filter out patients with empty patientId
      .map((patient, index) => ({
        id: `patient-${index + 1}`,
        patientName: `${patient.lastName || "Last Name"}, ${
          patient.firstName || "First Name"
        }`,
        firstName: patient.firstName,
        lastName: patient.lastName,
        patientId: patient.patientId || "Patient ID",
        birthDate: patient.birthDate
          ? patient.birthDate.toDate().toLocaleDateString("en-GB")
          : "DD/MM/YYYY",
        birthDateOriginal: patient.birthDate
          ? patient.birthDate.toDate()
          : null, // Preserve original Date object for sorting

        gender: genderMap[patient.genderIdx] || "Unknown",
        sessions: patientSessionsMap[patient.globalId] || [],
      }));
    setPatients(getAllPatients);
    setRows(formattedRows);
  };

  const fetchNewPatientData = async () => {
    const newData = await loadAllPatients(entityId.current, isOrgUser.current);
    handleDataChange(newData);
  };

  const handleDataChange = (newData) => {
    const formattedRows = newData
      .filter((patient) => patient.patientId) // Filter out patients with empty patientId
      .map((patient, index) => ({
        id: `patient-${index + 1}`,
        patientName: `${patient.lastName || "Last Name"}, ${
          patient.firstName || "First Name"
        }`,
        firstName: patient.firstName,
        lastName: patient.lastName,
        patientId: patient.patientId || "Patient ID",
        birthDate: patient.birthDate
          ? patient.birthDate.toDate().toLocaleDateString("en-GB")
          : "DD/MM/YYYY",
        birthDateOriginal: patient.birthDate
          ? patient.birthDate.toDate()
          : null, // Preserve original Date object for sorting

        gender: genderMap[patient.genderIdx] || "Unknown",
        sessions: newData[patient.globalId] || [],
      }));
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      formattedRows.forEach((newRow) => {
        const index = updatedRows.findIndex((row) => row.id === newRow.id);
        if (index !== -1) {
          updatedRows[index] = newRow;
        } else {
          updatedRows.push(newRow);
        }
      });
      return updatedRows;
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    const isEmptyA =
      !a[orderBy] ||
      a[orderBy] === "Patient ID" ||
      a[orderBy] === "DD/MM/YYYY" ||
      a[orderBy] === "Unknown";
    const isEmptyB =
      !b[orderBy] ||
      b[orderBy] === "Patient ID" ||
      b[orderBy] === "DD/MM/YYYY" ||
      b[orderBy] === "Unknown";

    if (isEmptyA && !isEmptyB) return 1;
    if (!isEmptyA && isEmptyB) return -1;
    if (isEmptyA && isEmptyB) return 0;

    if (orderBy === "birthDate") {
      const dateA = a.birthDateOriginal || new Date("9999-12-31"); // Use a distant future date if empty
      const dateB = b.birthDateOriginal || new Date("9999-12-31");
      return dateB < dateA ? -1 : dateB > dateA ? 1 : 0;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    const query = searchQuery.toLowerCase();
    return (
      row.patientName.toLowerCase().includes(query) ||
      row.patientId.toLowerCase().includes(query) ||
      row.birthDate.toLowerCase().includes(query) ||
      row.gender.toLowerCase().includes(query)
    );
  });

  if (!userData) {
    return (
      <Layout>
        <FreeUser></FreeUser>
      </Layout>
    );
  }

  return (
    <Layout>
      {userData && (
        <div style={{ padding: "20px" }}>
          {largestSubscriptionId > 0 ? (
            <>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Patients
              </div>
              <div style={{ color: "#999999" }}>
                A view of the existing patients
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "1em",
                  marginTop: "0.5em",
                }}
              >
                <PatientCreation
                  entityId={entityId.current}
                  isOrgUser={isOrgUser.current}
                  wrtPermissions={wrtPermissions.current}
                  reloadCallback={() => fetchNewPatientData()}
                />
                {isOrgUser.current === true ? (
                  <UploadPatientCSV
                    entityId={entityId.current}
                    isOrgUser={isOrgUser.current}
                    patientIds={patients}
                    reloadCallback={() => fetchNewPatientData()}
                  />
                ) : (
                  <div>
                    <button
                      className="button_no_style"
                      style={{
                        boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
                        borderRadius: "24px",
                        padding: "0px 20px",
                        backgroundColor: "#999999",
                      }}
                      // onClick={() => setOpen(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                        >
                          <img src={importPatient} style={{ width: "24px" }} />
                        </IconButton>

                        <div style={{ color: "white", fontSize: "16px" }}>
                          Import Patients
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
              <StyledTextField
                type="text"
                placeholder="Search "
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                style={{ marginLeft: "1em", marginBottom: "0.5em" }}
              />
              <Paper
                sx={{ height: "70vh", width: "100%", borderRadius: "10px" }}
              >
                <TableContainer
                  style={{ borderRadius: "10px", height: "70vh" }}
                >
                  <Table>
                    <TableHead className="table_header">
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "700" }}
                          sortDirection={
                            orderBy === "patientName" ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === "patientName"}
                            direction={
                              orderBy === "patientName" ? order : "asc"
                            }
                            onClick={() => handleRequestSort("patientName")}
                          >
                            Patient Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "700" }}
                          sortDirection={
                            orderBy === "patientId" ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === "patientId"}
                            direction={orderBy === "patientId" ? order : "asc"}
                            onClick={() => handleRequestSort("patientId")}
                          >
                            Patient ID
                          </TableSortLabel>{" "}
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "700" }}
                          sortDirection={
                            orderBy === "birthDate" ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === "birthDate"}
                            direction={orderBy === "birthDate" ? order : "asc"}
                            onClick={() => handleRequestSort("birthDate")}
                          >
                            Birth Date
                          </TableSortLabel>{" "}
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "700" }}
                          sortDirection={orderBy === "gender" ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === "gender"}
                            direction={orderBy === "gender" ? order : "asc"}
                            onClick={() => handleRequestSort("gender")}
                          >
                            Gender
                          </TableSortLabel>{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "700" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(
                        filteredRows,
                        getComparator(order, orderBy)
                      ).map((row) => (
                        <React.Fragment key={row.id}>
                          <TableRow
                            className="tableRowHover"
                            style={{
                              ...(openPatients[row.id]
                                ? { backgroundColor: "#F1E7FF" }
                                : {}),
                            }}
                            onClick={() => togglePatientOpen(row.id)}
                          >
                            <TableCell
                              style={{
                                color:
                                  row.patientName === "Last Name, First Name"
                                    ? "#999999"
                                    : "inherit",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {openPatients[row.id] ? (
                                  <ExpandLess
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      togglePatientOpen(row.id);
                                    }}
                                  />
                                ) : (
                                  <ExpandMore
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      togglePatientOpen(row.id);
                                    }}
                                  />
                                )}
                                <span style={{ marginLeft: "0.5em" }}>
                                  {row.patientName}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color:
                                  row.patientId === "Patient ID"
                                    ? "#999999"
                                    : "inherit",
                              }}
                            >
                              {row.patientId}
                            </TableCell>
                            <TableCell
                              style={{
                                color:
                                  row.birthDate === "DD/MM/YYYY"
                                    ? "#999999"
                                    : "inherit",
                              }}
                            >
                              {row.birthDate}
                            </TableCell>
                            <TableCell
                              style={{
                                color:
                                  row.gender === "Unknown"
                                    ? "#999999"
                                    : "inherit",
                              }}
                            >
                              {row.gender}
                            </TableCell>
                            <TableCell>
                              <ModalEditPatient
                                entityId={entityId.current}
                                isOrgUser={isOrgUser.current}
                                wrtPermissions={wrtPermissions.current}
                                firstNameExist={row.firstName}
                                lastNameExist={row.lastName}
                                patientIdExist={row.patientId}
                                birthDateExist={row.birthDate}
                                genderExist={row.gender}
                                reloadCallback={() => fetchNewPatientData()}
                                allPatients={allPatients}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={openPatients[row.id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <TableContainer>
                                  <Table>
                                    {row.sessions.length === 0 ? (
                                      ""
                                    ) : (
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            style={{ fontWeight: "700" }}
                                          >
                                            Examination Date
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "700" }}
                                          >
                                            Description
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "700" }}
                                          >
                                            Tags
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "700" }}
                                          >
                                            Clinician
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "700" }}
                                          >
                                            Technician
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                    )}
                                    <TableBody>
                                      {row.sessions.length === 0 ? (
                                        <>
                                          <TableRow>
                                            <TableCell
                                              colSpan={4}
                                              style={{
                                                color: "#999999",
                                              }}
                                            >
                                              This patient contains no sessions
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      ) : (
                                        row.sessions
                                          .sort(
                                            (a, b) =>
                                              b.createdAt.toDate() -
                                              a.createdAt.toDate()
                                          ) // Sort sessions from newest to oldest
                                          .map((session, sessionIndex) => (
                                            <TableRow
                                              key={sessionIndex}
                                              className="tableRowHover"
                                            >
                                              <TableCell
                                                colSpan={5}
                                                style={{ padding: 0 }}
                                              >
                                                <Link
                                                  to={`/sessions/${session.globalId}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                    display: "block",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <TableCell
                                                      style={{ width: "20%" }}
                                                    >
                                                      {session.createdAt
                                                        .toDate()
                                                        .toLocaleString(
                                                          "default",
                                                          {
                                                            month: "2-digit",
                                                            day: "2-digit",
                                                            year: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            hour12: false,
                                                          }
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        width: "20%",
                                                        color:
                                                          session.description ===
                                                          ""
                                                            ? "#999999"
                                                            : "inherit",
                                                      }}
                                                    >
                                                      {session.description
                                                        ? session.description
                                                        : "No description"}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        width: "20%",
                                                        color:
                                                          session.tags
                                                            .length === 0
                                                            ? "#999999"
                                                            : "inherit",
                                                      }}
                                                    >
                                                      {session.tags.join(
                                                        ", "
                                                      ) || "No tags"}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        width: "20%",
                                                        color:
                                                          session.ownerName ===
                                                          "Unassigned"
                                                            ? "#999999"
                                                            : "inherit",
                                                      }}
                                                    >
                                                      {session.ownerName ===
                                                      "Unassigned"
                                                        ? "Unassigned"
                                                        : `Dr. ${session.ownerName}`}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        width: "20%",
                                                        color:
                                                          session.techName ===
                                                          "Unassigned"
                                                            ? "#999999"
                                                            : "inherit",
                                                      }}
                                                    >
                                                      {session.techName}
                                                    </TableCell>
                                                  </div>
                                                </Link>
                                              </TableCell>
                                            </TableRow>
                                          ))
                                      )}
                                      <TableRow>
                                        <TableCell colSpan={5}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            {(userData &&
                                              userData.storageEmailLastThreshold ===
                                                1) ||
                                            wrtPermissions.current === 0 ? (
                                              <Tooltip
                                                title={
                                                  wrtPermissions.current === 0
                                                    ? "You don't have the required permissions to create a new session"
                                                    : ""
                                                }
                                              >
                                                <button className="gray_new_session button_no_style ">
                                                  <div className="centered_items_flex_div">
                                                    <IconButton
                                                      type="button"
                                                      sx={{
                                                        p: "10px",
                                                        color: "#999999",
                                                      }}
                                                      aria-label="search"
                                                    >
                                                      <Add />
                                                    </IconButton>
                                                    <div className="inactive_session">
                                                      New Session
                                                    </div>
                                                  </div>
                                                </button>
                                              </Tooltip>
                                            ) : (
                                              <>
                                                <SessionCreation
                                                  allPatients={allPatients}
                                                  allTagsArray={allTags}
                                                  entityId={entityId.current}
                                                  isOrgUser={isOrgUser.current}
                                                  wrtPermissions={
                                                    wrtPermissions.current
                                                  }
                                                  selectedPatient={
                                                    row.patientId
                                                  }
                                                />
                                              </>
                                            )}{" "}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          ) : (
            <div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
                </div>
              ) : (
                <div>{isOrgUser.current ? <FreeOrg /> : <FreeUser />}</div>
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}

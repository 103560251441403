import React, { useEffect, useRef, useState } from "react";
import "../../style/dashboard.css";
import Layout from "../Layout";
import { Container } from "@mui/system";
import axios from "axios";
import { useAuth, getAuthData } from "../../context/AuthContext";
import Activate from "./activation";
import activation from "../../images/login/activation.svg";
import info from "./info.svg";
import MobileSubs from "./mobile_subs";
import { HashLink } from "react-router-hash-link";
import { Box, CircularProgress, Grid } from "@mui/material";
import firestoreRepository from "../../repositories/firestoreRepository";
import { Done } from "@mui/icons-material";
import mark from "./mark.svg";
import error_purple from "./error_purple.svg";
import feedback from "./feedback.svg";
import { Link } from "react-router-dom";

const SubsPage = () => {
  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState({});
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const isOrgUser = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await firestoreRepository.getUserData(currentUser.uid);
        setLargestSubscriptionId(
          Math.max(
            userData.stripeSubscriptionId || 0,
            userData.iosSubscriptionId || 0,
            userData.androidSubscriptionId || 0,
            userData.premiumAndroid ? 2 : 0,
            userData.premiumIOS ? 2 : 0
          )
        );
        setUserData(userData);

        const result = await getAuthData();
        setAuthData(result);
        if (!result.claims.orgId) {
          isOrgUser.current = false;
        } else {
          isOrgUser.current = true;
        }

        // Set loading to false after all data is loaded
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Set loading to false even if there is an error
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const createBillingPortalSession = async () => {
    if (!authData.token) {
      console.error("User is not authenticated.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/createStripeBillingPortalConfig`,

        {
          // Any data you want to send to the Cloud Function
          // For example, you can send an empty object if no data is needed.
        },
        {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        }
      );
      handleBillingPortalClick(response.data.portalSession);
    } catch (error) {
      console.error(error);
    } finally {
      // Hide the loading indicator whether the request succeeds or fails
      setLoading(false);
    }
  };

  const handleBillingPortalClick = (billingPortalUrl) => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
        </div>
      ) : (
        <div style={{ padding: "20px" }}>
          {userData && (
            <>
              {isOrgUser.current ? (
                <>
                  <div style={{ fontWeight: "700", fontSize: "20px" }}>
                    Subscription{" "}
                  </div>
                  <div style={{ marginTop: "2em", fontWeight: "600" }}>
                    CURRENT PLAN{" "}
                  </div>
                  <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "stretch",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={5.8}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#E6D8FF",
                          padding: "20px 40px",
                          borderRadius: "10px",
                          marginTop: "1em",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: "1em",
                          }}
                        >
                          Pilot Program Member
                        </div>
                        <div>
                          You're part of our exclusive pilot program. Your
                          feedback is crucial in shaping the future of our
                          platform.
                        </div>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 8px 0px #3A5C9D24",
                          padding: "20px 40px",
                          borderRadius: "10px",
                          marginTop: "2em",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            marginBottom: "1em",
                          }}
                        >
                          Program Benefits
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5em",
                          }}
                        >
                          <Done
                            style={{ color: "#8F4FFF", marginRight: "0.5em" }}
                          />
                          <span>Exclusive lower pricing</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5em",
                          }}
                        >
                          <Done
                            style={{ color: "#8F4FFF", marginRight: "0.5em" }}
                          />
                          <span>Priority feedback implementation</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Done
                            style={{ color: "#8F4FFF", marginRight: "0.5em" }}
                          />
                          <span>Early access to new features</span>
                        </div>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 8px 0px #3A5C9D24",
                          padding: "20px 0px",
                          borderRadius: "10px",
                          marginTop: "2em",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1em",
                            paddingLeft: "10px",
                          }}
                        >
                          <img src={mark}></img>
                          <div
                            style={{
                              fontWeight: "700",
                              marginLeft: "0.5em",
                            }}
                          >
                            Subscription Details
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "0px 40px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <div>
                            Your subscription is managed by our team to ensure
                            you receive the best pilot program experience.
                          </div>
                          <div style={{ fontSize: "14px", marginTop: "1em" }}>
                            <div style={{ fontWeight: "500" }}>Note</div>
                            <div
                              style={{
                                marginTop: "0.5em",
                                marginBottom: "1.5em",
                              }}
                            >
                              For any changes or inquiries, please contact us
                              through the chat.
                            </div>
                          </div>
                          <a
                            style={{
                              padding: "10px 40px",
                              color: "white",
                              backgroundColor: "#8F4FFF",
                              borderRadius: "10px",
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              fontWeight: "600",
                              justifyContent: "center",
                              marginBottom: "2.5em",
                              border: "none",
                            }}
                            href="mailto:support@customsurgical.co"
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5.8}
                      style={{
                        boxShadow: "0px 2px 8px 0px #3A5C9D24",
                        padding: "20px 0px",
                        borderRadius: "10px",
                        marginTop: "1em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1em",
                          paddingLeft: "10px",
                        }}
                      >
                        <img src={error_purple}></img>
                        <div
                          style={{
                            fontWeight: "700",
                            marginLeft: "0.5em",
                          }}
                        >
                          We Value Your Input
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "0px 40px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <div>
                          Your feedback is essential for improving MicroREC
                          Connect platform. We encourage you to share your
                          thoughts and experiences.
                        </div>
                        <img src={feedback}></img>
                        <a
                          href="https://forms.gle/ErMpxQpJf1uY6dQa8"
                          target="_blank"
                          style={{
                            padding: "10px 40px",
                            color: "white",
                            backgroundColor: "#8F4FFF",
                            borderRadius: "10px",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontWeight: "600",
                            justifyContent: "center",
                            marginBottom: "2.5em",
                            border: "none",
                          }}
                        >
                          Provide Feedback{" "}
                        </a>
                      </div>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <div style={{ fontWeight: "700", fontSize: "20px" }}>
                    Subscription{" "}
                  </div>
                  <div style={{ marginTop: "2em", fontWeight: "600" }}>
                    CURRENT PLAN{" "}
                  </div>
                  <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                      margin: "2em 0",
                    }}
                  >
                    <Grid item sx={12} sm={5} md={5}>
                      {largestSubscriptionId === 0 ? (
                        <div
                          style={{
                            padding: "30px 0",
                            width: "100%",
                            borderRadius: "20px",
                            fontWeight: "700",
                            boxShadow:
                              "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                            margin: "2.5em 0",
                            textAlign: "center",
                            color: "#999999",
                          }}
                        >
                          You don’t have any subscription
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: "30px 0",
                            width: "100%",
                            borderRadius: "20px",
                            fontWeight: "700",
                            boxShadow:
                              "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                            margin: "2.5em 0",
                            textAlign: "center",
                          }}
                        >
                          {largestSubscriptionId === 2 ||
                          largestSubscriptionId === 9
                            ? "MicroREC Connect - Standard"
                            : ""}
                          {largestSubscriptionId === 1
                            ? "MicroREC Connect - Mini"
                            : ""}
                          {largestSubscriptionId === 3
                            ? "MicroREC Connect - Premium"
                            : ""}
                        </div>
                      )}

                      {largestSubscriptionId === 0 ? (
                        <HashLink
                          style={{
                            padding: "15px 40px",
                            color: "white",
                            backgroundColor: "#8F4FFF",
                            borderRadius: "20px",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontWeight: "700",
                            justifyContent: "center",
                            marginBottom: "2.5em",
                          }}
                          to="/subscription#subs"
                        >
                          Check Subscription Plans
                        </HashLink>
                      ) : (
                        <>
                          {userData.premiumIOS === true ||
                          userData.premiumAndroid === true ||
                          userData.iosSubscriptionId >
                            userData.stripeSubscriptionId ||
                          userData.androidSubscriptionId >
                            userData.stripeSubscriptionId ? (
                            <HashLink
                              to="/subscription#subs"
                              style={{
                                padding: "15px 40px",
                                color: "white",
                                backgroundColor: "#8F4FFF",
                                borderRadius: "20px",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                fontWeight: "700",
                                justifyContent: "center",
                                marginBottom: "2.5em",
                              }}
                            >
                              Check Subscription Plans
                            </HashLink>
                          ) : (
                            <>
                              <button
                                style={{ width: "100%" }}
                                className="button_no_style"
                                onClick={createBillingPortalSession}
                              >
                                <div
                                  style={{
                                    padding: "15px 40px",
                                    color: "white",
                                    backgroundColor: "#8F4FFF",
                                    borderRadius: "20px",
                                    fontSize: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    fontWeight: "700",
                                    justifyContent: "center",
                                    marginBottom: "2.5em",
                                  }}
                                >
                                  {loading
                                    ? "Loading..."
                                    : "Manage subscription"}
                                </div>
                              </button>{" "}
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>Want to upgrade?</div>
                                <HashLink
                                  style={{
                                    color: "#8F4FFF",
                                    textDecoration: "underline",
                                    fontWeight: "700",
                                  }}
                                  to="/subscription/#subs"
                                >
                                  Check plans
                                </HashLink>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Grid>{" "}
                    {largestSubscriptionId === 0 ? (
                      ""
                    ) : (
                      <>
                        <Box
                          sx={{ display: { xs: "none", sm: "block" } }}
                          style={{
                            backgroundColor: "#D9D9D9",
                            width: "2px",
                          }}
                        ></Box>
                        <Grid
                          xs={12}
                          sm={5}
                          md={5}
                          sx={{ marginTop: { xs: "2em", sm: "0" } }}
                        >
                          <ul style={{ padding: "0" }}>
                            What benefits are included:
                          </ul>
                          {largestSubscriptionId === 2 ||
                          largestSubscriptionId === 9 ? (
                            <li>500 GB of cloud storage.</li>
                          ) : (
                            ""
                          )}
                          {largestSubscriptionId === 1 ? (
                            <li>10 GB of cloud storage.</li>
                          ) : (
                            ""
                          )}
                          {largestSubscriptionId === 3 ? (
                            <li>2 TB of cloud storage.</li>
                          ) : (
                            ""
                          )}
                          <li style={{ marginTop: "1em" }}>
                            Multi-device access.
                          </li>
                          <li style={{ marginTop: "1em" }}>
                            Unlimited link share of images and video.
                          </li>
                          <li style={{ marginTop: "1em" }}>
                            In-app video edition.
                          </li>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {userData.premiumIOS === true ||
                  userData.premiumAndroid === true ||
                  userData.iosSubscriptionId > userData.stripeSubscriptionId ||
                  userData.androidSubscriptionId >
                    userData.stripeSubscriptionId ? (
                    <Grid
                      container
                      justifyContent={{ xs: "space-between", md: "center" }}
                      style={{
                        marginTop: "1em",
                        backgroundColor: "#E6D8FF",
                        padding: "10px",
                        borderRadius: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={1} md={1}>
                        <img src={info}></img>
                      </Grid>
                      <Grid item xs={9} md={7}>
                        <div
                          style={{ fontWeight: "700", marginBottom: "0.5em" }}
                        >
                          What happens if you already have a subscription with
                          the App or Play Store?
                        </div>{" "}
                        <div>
                          You can always cancel those subscriptions, and
                          subscribe here to get the best prices.
                        </div>{" "}
                      </Grid>{" "}
                      <Grid
                        item
                        xs={12}
                        md={3}
                        marginTop={{ xs: "1em", md: "0" }}
                      >
                        <MobileSubs />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginTop: "2em",
                      fontWeight: "600",
                    }}
                  >
                    <img
                      src={activation}
                      style={{ marginRight: "0.5em" }}
                    ></img>
                    <div>ACTIVATE LICENSE CODE</div>
                  </div>{" "}
                  <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{ marginTop: "1em" }}
                  >
                    <Grid item xs={12} md={5} style={{ marginBottom: "1em" }}>
                      <div>
                        If you have a license activation code for MicroREC
                        Connect, you can activate it here.
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Activate />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

export default SubsPage;

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository from "../../repositories/firestoreRepository";
import "../dashboard/Dashboard.css";
import { AllOwners } from "../../utils/ownerUtils";
import { UploadContext } from "../App";
import { AllTechnicians, Technician } from "../../utils/technicianUtils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #8f4fff;
    }
  }
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 10px;
    &.Mui-focused fieldset {
      border-color: #8f4fff;
    }
  }
`;

const ModalCreateSession = ({
  allPatients,
  allTagsArray,
  entityId,
  isOrgUser,
  wrtPermissions,
  selectedPatient = "",
}) => {
  if (!allTagsArray) {
    allTagsArray = [];
  }
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [allTags, setAllTags] = useState(allTagsArray);
  const [patientId, setPatientId] = useState("");
  const [description, setDescription] = useState("");
  const [patientIdDuplicate, setPatientIdDuplicate] = useState({});
  const { selectedOwner, setSelectedOwner } = useContext(UploadContext);
  const { selectedTechnician, setSelectedTechnician } =
    useContext(UploadContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const patientsData = await firestoreRepository.getAllPatients(
        entityId,
        isOrgUser
      );
      var tempPatients = {};
      patientsData.forEach((patient) => {
        const globalId = patient.globalId;
        const canonicalId = patient.canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      setPatientIdDuplicate(tempPatients);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setAllTags(allTagsArray);
  }, [allTagsArray]);

  useEffect(() => {
    if (selectedPatient) {
      setPatientId(selectedPatient);
    }
  }, [selectedPatient]);

  const handleTextAreaChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const cleanData = () => {
    setDescription("");
    setTagsSelected([]);
  };

  const handleModalClose = () => {
    handleClose();
    cleanData();
  };

  const createNewSession = async () => {
    const canonicalId = getCanonicalPatientId(patientId);

    const duplicatedPatient = Object.entries(patientIdDuplicate).find(
      ([_, value]) => value === canonicalId
    );

    let sessionId;

    if (!duplicatedPatient) {
      const patientGlobalId = await firestoreRepository.createPatient(
        entityId,
        isOrgUser,
        patientId,
        false
      );
      // Only create session after successful patient creation
      const sessionData = {
        description: description,
        tags: tagsSelected,
        patientGlobalId: patientGlobalId,
        owner: selectedOwner,
        technician: selectedTechnician,
      };

      sessionId = await firestoreRepository.createSession(
        entityId,
        isOrgUser,
        sessionData,
        true
      );
    } else {
      const [duplicatedPatientGlobalId, _] = duplicatedPatient;
      await firestoreRepository.updatePatient(
        entityId,
        isOrgUser,
        duplicatedPatientGlobalId,
        patientId,
        false
      );
      // Only update session info after successful patient update
      const sessionData = {
        description: description,
        tags: tagsSelected,
        patientGlobalId: duplicatedPatientGlobalId,
        owner: selectedOwner,
        technician: selectedTechnician,
      };

      sessionId = await firestoreRepository.createSession(
        entityId,
        isOrgUser,
        sessionData,
        true
      );
    }
    navigateToSession(sessionId);
    handleModalClose();
  };

  const navigateToSession = (id) => {
    const path = `/sessions/${id}`;
    if (location.pathname === "/patients") {
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <div>
        {location.pathname === "/patients" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="button_no_style"
              style={{
                marginRight: "1em",
                boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
                borderRadius: "24px",
                padding: "0px 10px",
                backgroundColor: "white",
                border: "1px solid #8F4FFF",
              }}
              onClick={() => setOpen(true)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="button"
                  // sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <AddIcon sx={{ color: "#8F4FFF" }} />
                </IconButton>

                <div
                  style={{
                    color: "#8F4FFF",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  New Session
                </div>
              </div>
            </button>
          </div>
        ) : (
          <button
            className="button_no_style"
            style={{
              marginRight: "1em",
              boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
              borderRadius: "24px",
              padding: "0px 20px",
              backgroundColor: "#8F4FFF",
            }}
            onClick={() => setOpen(true)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <AddIcon sx={{ color: "white" }} />
              </IconButton>

              <div style={{ color: "white", fontSize: "16px" }}>
                New Session
              </div>
            </div>
          </button>
        )}

        <BootstrapDialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleModalClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Create New Session
                    </div>
                  </div>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{
                      marginTop: "1em",
                      padding: "30px",
                    }}
                  >
                    <Grid item xs={12} sm={5.8}>
                      <label className="label">Patient Id:</label>
                      <Autocomplete
                        options={allPatients}
                        getOptionLabel={(option) => {
                          // Ensure that the option is not displayed if patientId is empty or undefined
                          if (typeof option === "string") {
                            return option;
                          }
                          return option.patientId || "";
                        }}
                        style={{
                          width: "100%",
                          resize: "none",
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "2em",
                        }}
                        onChange={(event, newValue) => {
                          if (
                            typeof newValue === "string" &&
                            newValue.startsWith("Create Patient: ")
                          ) {
                            const patientId = newValue.slice(16);
                            setPatientId(patientId);
                          } else if (
                            newValue &&
                            typeof newValue === "object" &&
                            newValue.patientId
                          ) {
                            setPatientId(newValue.patientId);
                          } else {
                            setPatientId("");
                          }
                        }}
                        value={
                          allPatients?.find(
                            (option) => option.patientId === patientId
                          ) || { patientId: patientId }
                        }
                        onInputChange={(event, newInputValue) => {
                          setPatientId(newInputValue);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            label={patientId ? "" : "Add the Patient ID"}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {typeof option === "string"
                              ? option
                              : option.patientId}
                          </li>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = options.filter((option) => {
                            // Exclude options where patientId is empty or undefined
                            return (
                              option?.patientId &&
                              option.patientId
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            );
                          });

                          if (
                            params.inputValue !== "" &&
                            !filtered.some(
                              (option) =>
                                option.patientId.toLowerCase() ===
                                params.inputValue.toLowerCase()
                            )
                          ) {
                            filtered.push(
                              `Create Patient: ${params.inputValue}`
                            );
                          }

                          const selectedOptions = Array.isArray(params.value)
                            ? params.value.map((option) => option)
                            : [];
                          const combinedOptions = [
                            ...new Set([...selectedOptions, ...filtered]),
                          ];

                          return combinedOptions.slice(0, 5);
                        }}
                      />

                      <label className="label">Date:</label>
                      <div>
                        <Tooltip title="You cannot change the date">
                          <StyledTextField
                            label={new Date().toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}
                            style={{
                              width: "100%",
                              resize: "none",
                              display: "flex",
                              marginTop: "1em",
                              marginBottom: "2em",
                            }}
                            disabled={true}
                          />
                        </Tooltip>
                      </div>
                      <label className="label">Tags:</label>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          height: "104px",
                          overflowY: "auto",
                        }}
                        className="margin_top_wrap"
                      >
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          style={{ width: "100%" }}
                          options={allTags.map((option) => option)}
                          freeSolo
                          filterOptions={(options, params) => {
                            const filtered = options.filter((option) => {
                              return option
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase());
                            });
                            if (
                              params.inputValue !== "" &&
                              !filtered.some(
                                (option) =>
                                  option.toLowerCase() ===
                                  params.inputValue.toLowerCase()
                              )
                            ) {
                              filtered.push(`Create tag: ${params.inputValue}`);
                            }

                            const selectedOptions = Array.isArray(params.value)
                              ? params.value.map((option) => option)
                              : [];
                            const combinedOptions = [
                              ...new Set([...selectedOptions, ...filtered]),
                            ];

                            // Return a maximum of 5 options, prioritizing selected options
                            return combinedOptions.slice(0, 5);
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              const displayValue = option.startsWith(
                                "Create tag: "
                              )
                                ? option.slice(12)
                                : option;
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              return (
                                <Chip
                                  variant="outlined"
                                  label={displayValue}
                                  key={key}
                                  {...tagProps}
                                />
                              );
                            })
                          }
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              label={
                                tagsSelected?.length < 1 ? "Add a tag" : ""
                              }
                            />
                          )}
                          onChange={(event, newValue) => {
                            const finalTags = newValue.map((tag) => {
                              return tag.startsWith("Create tag: ")
                                ? tag.slice(12)
                                : tag;
                            });
                            setTagsSelected(finalTags);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {isOrgUser === true ? (
                        <>
                          <label className="label">Clinician:</label>
                          <AllOwners
                            sessionOwner={selectedOwner}
                            entityId={entityId}
                            wrtPermissions={wrtPermissions}
                          />
                          <label className="label">Technician:</label>
                          <AllTechnicians
                            // sessionTechnician={technician}
                            entityId={entityId}
                            wrtPermissions={wrtPermissions}
                          />
                        </>
                      ) : (
                        ""
                      )}

                      <label className="label">Comment:</label>
                      <div style={{ flexGrow: "1", height: "100%" }}>
                        <StyledTextField
                          onChange={handleTextAreaChangeDescription}
                          value={description}
                          label={
                            description
                              ? ""
                              : "A comment or note about a patient, photo or video description"
                          }
                          multiline
                          rows={isOrgUser === true ? 2.5 : 11.5}
                          style={{
                            width: "100%",
                            resize: "none",
                            display: "flex",
                            marginTop: "1em",
                            marginBottom: "2em",
                          }}
                          InputProps={{
                            style: {
                              height: "100%",
                              resize: "none",
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      style={{
                        marginTop: "1em",
                        width: "60%",
                        backgroundColor: "#8F4FFF",
                        padding: "15px 20px",
                      }}
                      className="button"
                      onClick={() => {
                        createNewSession();
                      }}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ModalCreateSession;
